import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/layouts/Layout";

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <section className="page-not-found">
      <div className="content container">
        <h2 className="mb-3">¡UPS!</h2>
        <h3 className="mb-3">Página no encontrada</h3>
        <p className="mb-3">
          Lo sentimos, no pudimos encontrar la página que estabas buscando. Te
          sugerimos que regreses a la página principal.
        </p>
        <button className="btn btn-primary" onClick={() => navigate("/")}>
          VOLVER
        </button>
      </div>
    </section>
  );
};

export default NotFound;
