import { combineReducers } from "redux";
import userSlice from "../userSlice";
import cartSlice from "../cartSlice";
import loadingSlice from "../loadingSlice";


const rootReducer = combineReducers({
  User: userSlice,
  Cart: cartSlice,
  Loading: loadingSlice,
});

export default rootReducer;


