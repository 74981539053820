import React, { useEffect } from "react";
import { AddingScript } from "../hooks/useScripts";

// Definir interfaz global de Culqi para TypeScript
declare global {
  interface Window {
    Culqi: any;
  }
}

const CulqiCheckout: React.FC = () => {
  const handleCheckout = async () => {
    await AddingScript("https://checkout.culqi.com/js/v4");
    if (!window.Culqi) {
      console.error(
        "Culqi no está definido. Asegúrate de que el script se haya cargado correctamente.",
      );
      return;
    }

    try {
      // Configurar el modal de Culqi antes de abrirlo
      window.Culqi.publicKey = "pk_test_7487927a7435f86c"; // Reemplaza con tu llave pública

      // Configuración del modal
      window.Culqi.settings({
        title: "Ilaini",
        currency: "PEN",
        amount: 8000, // Monto en céntimos (80.00 PEN)
        order: "ord_live_d1P0Tu1n7Od4nZdp",
        options: {
          lang: "auto",
          installments: true,
          modal: true,
        },
        appearance: {
          theme: "default",
          logo: "http://www.childrensociety.ms/wp-content/uploads/2019/11/MCS-Logo-2019-no-text.jpg",
          buttonCardPayText: "Pagar tal monto",
        },
      });

      // Definir callback para manejar las acciones de Culqi
      window.culqi = function () {
        if (window.Culqi.token) {
          const token = window.Culqi.token.id;
          console.log("Token creado:", token);

          //llamada a la api
          // Aquí puedes enviar el token al backend para procesar el pago
        } else if (window.Culqi.order) {
          const order = window.Culqi.order;
          console.log("Orden creada:", order);
        } else {
          console.error("Error en Culqi:", window.Culqi.error);
        }
      };

      window.Culqi.open();
    } catch (error) {
      console.error("Error al abrir el checkout de Culqi:", error);
    }
  };
  return (
    <div>
      <h1>Checkout</h1>
      <div id="culqi-container"></div>{" "}
      {/* Contenedor para el modal, si es necesario */}
      <button onClick={handleCheckout}>Pagar</button>
    </div>
  );
};

export default CulqiCheckout;
