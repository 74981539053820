import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Autoplay,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import {
  ColorProduct,
  getDetailProduct,
  ProductGeneral,
  SizeProduct,
} from "../../services/productsService";
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoaderNew from "../../components/utils/FullScreenLoaderNew";
import { addToCart } from "../../store/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/config/redux-persist";
import { startLoading, stopLoading } from "../../store/loadingSlice";

const ImageSlider = ({ images, selectedImage, setSelectedImage }: any) => (
  <div className="product-single__thumbnail">
    <div className="thumbnail-container flex lg:flex-col w-full">
      {images.map((img: string, index: number) => (
        <div
          key={index}
          className={`product-single__image-item cursor-pointer ${
            selectedImage === img ? "" : "opacity-50"
          }`}
          onClick={() => setSelectedImage(img)}
        >
          <img
            loading="lazy"
            className="h-auto"
            src={img}
            width="104"
            height="104"
            alt={`Detail ${index + 1}`}
          />
        </div>
      ))}
    </div>
  </div>
);

// Componente para seleccionar las tallas
const SizeSelector = ({ sizes, selectedSize, setSelectedSize }: any) => (
  <div className="mt-6">
    <p className="text-[8px]" style={{ letterSpacing: 1 }}>
      Guía de tallas
    </p>
    <div className="mt-3">
      <div className="flex space-x-2">
        {sizes.length > 0 ? (
          sizes.map((size: SizeProduct) => (
            <button
              key={size.id}
              onClick={() => setSelectedSize(size)}
              className={`px-2 py-1 text-sm text-black ${
                selectedSize?.id === size.id ? "border" : ""
              }`}
            >
              {size.name}
            </button>
          ))
        ) : (
          <span>No hay tallas disponibles</span>
        )}
      </div>
    </div>
  </div>
);

// Componente para seleccionar los colores
const ColorSelector = ({
  colors,
  selectedColor,
  setSelectedColor,
  setSelectedSize,
}: any) => (
  <div className="mt-3.5">
    <p className="text-[11px]" style={{ letterSpacing: 1 }}>
      Colores Disponibles
    </p>
    <div className="mt-3">
      <div className="flex space-x-2">
        {colors.length > 0 ? (
          colors.map((color: ColorProduct) => (
            <button
              key={color.id}
              onClick={() => {
                setSelectedColor(color);
                setSelectedSize(color.sizes[0]); // Actualiza el tamaño al cambiar de color
              }}
              className={`w-[29px] h-[29px] rounded-full ${
                selectedColor?.id === color.id
                  ? "ring-2 ring-offset-2 ring-gray-800"
                  : ""
              }`}
            >
              <img
                src={color.imagePath}
                alt={color.name}
                className="w-full h-full object-cover rounded-full"
              />
            </button>
          ))
        ) : (
          <span>No hay colores disponibles</span>
        )}
      </div>
    </div>
  </div>
);

const ProductDetail = () => {
  const [selectedSize, setSelectedSize] = useState<SizeProduct | null>(null);
  const [selectedColor, setSelectedColor] = useState<ColorProduct | null>(null);
  const [product, setProduct] = useState<ProductGeneral | null>(null);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [colorImages, setColorImages] = useState<string[]>([]);
  const [compositionShow, setCompositionShow] = useState(false);
  const [careShow, setCareShow] = useState(false);
  const { nameProduct } = useParams<{ nameProduct: string }>();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.Loading.isLoading);

  useEffect(() => {
    initial();
  }, []);

  const initial = async () => {
    dispatch(startLoading());
    try {
      const { data } = await getDetailProduct(nameProduct || "");
      if (data) {
        setProduct(data as ProductGeneral);
        if (data.colors && data.colors.length > 0) {
          const firstColor = data.colors[0];
          setSelectedColor(firstColor);
          setColorImages(firstColor.images.map((img: any) => img.imagePath));
          setSelectedImage(firstColor.images?.[0]?.imagePath || "");

          if (firstColor.sizes && firstColor.sizes.length > 0) {
            const firstSize = firstColor.sizes[0];
            setSelectedSize(firstSize);
          }
        }
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      window.location.href = "/";
      console.error("Error fetching product details", error);
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleAddProduct = (product: ProductGeneral) => {
    dispatch(
      addToCart({
        ...product,
        selectedColor: selectedColor?.name || "",
        selectedImage: selectedImage,
        selectedSize: selectedSize?.name || "",
      })
    );
  };

  useEffect(() => {
    if (selectedColor) {
      setColorImages(selectedColor.images.map((img: any) => img.imagePath));
      setSelectedImage(selectedColor.images?.[0]?.imagePath || "");
    }
  }, [selectedColor]);

  if (isLoading || !product) {
    return <FullScreenLoaderNew />;
  }

  return (
    <section className="product-single container px-4">
      <div className="row">
        <div className="col-lg-7">
          <div className="product-single__media vertical-thumbnail product-media-initialized">
            <div className="product-single x1280:flex lg:flex">
              <div className="product-single__image">
                <Swiper
                  className="swiper-container"
                  modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                  slidesPerView={1}
                >
                  <SwiperSlide>
                    <div className="swiper-slide product-single__image-item">
                      <img
                        loading="lazy"
                        className="h-auto"
                        src={selectedImage}
                        width="674"
                        height="674"
                        alt="Product detail"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              {colorImages.length > 0 ? (
                <ImageSlider
                  images={colorImages}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              ) : (
                <p>No hay imágenes disponibles</p>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-5">
          <h1
            className="mt-14 flex text-primaryColor text-3xl uppercase"
            style={{ letterSpacing: 2 }}
          >
            {product?.name}
          </h1>
          <div className="product-single__price">
            <span
              className="flex text-primaryColor text-3xl uppercase font-light"
              style={{ letterSpacing: 3 }}
            >
              S/.{product?.price && product?.price.toFixed(2)}
            </span>
          </div>

          {selectedColor && (
            <ColorSelector
              colors={product?.colors || []}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              setSelectedSize={setSelectedSize}
            />
          )}

          <SizeSelector
            sizes={selectedColor?.sizes || []}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
          />
          <div className="mt-8">
            <div>
              <div className="flex items-center justify-between">
                <h2 className="uppercase text-primaryColor  text-sm">
                  Composición de prenda
                </h2>
                <button
                  className="text-2xl"
                  onClick={() => setCompositionShow(!compositionShow)}
                >
                  {compositionShow ? "-" : "+"}
                </button>
              </div>
              {compositionShow ? (
                <div>{product?.materialComposition}</div>
              ) : null}
            </div>
            <hr className="my-6 bg-black h-5 border-none" />
            <div>
              <div className="flex items-center justify-between">
                <h2 className="uppercase text-primaryColor text-sm">
                  Cuidados de prenda
                </h2>
                <button
                  className="text-2xl"
                  onClick={() => setCareShow(!careShow)}
                >
                  {careShow ? "-" : "+"}
                </button>
              </div>
              {careShow ? <div>{product?.garmentCare}</div> : null}
            </div>
          </div>

          <form name="addtocart-form" method="post">
            <div className="mt-6 product-single__addtocart">
              <button
                type="button"
                onClick={() => handleAddProduct(product)}
                className="w-286px h-34px text-xs flex items-center btn btn-primary"
              >
                AGREGAR AL CARRITO
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="container mt-11 mb-11">
        <h2 className="text-[15px] uppercase mb-3" style={{ letterSpacing: 1 }}>
          También podría gustarte
        </h2>
        <div className="flex flex-wrap justify-center gap-4"></div>
      </div>
    </section>
  );
};

export default ProductDetail;
