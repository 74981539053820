import React, { useState, useEffect } from "react";
import exampleProduct1 from "../../assets/images/example_product1.jpg";
import exampleProduct2 from "../../assets/images/example_product2.jpg";
import exampleProduct3 from "../../assets/images/example_product3.jpg";
import exampleProduct4 from "../../assets/images/example_product4.jpg";
import CardProduct from "../../components/home/CardProduct";
import { Link } from "react-router-dom";

const CategoryWoman = () => {
  return (
    <section className="product-single container">
      <nav
        className="font-regular py-6 text-[8px] uppercase  px-3 2xl:mx-[130px]"
        style={{ letterSpacing: 3 }}
      >
        <Link to="/" className="hover:underline">
          Inicio
        </Link>{" "}
        &gt;
        <Link to="/" className="hover:underline ml-2">
          Mujer
        </Link>
      </nav>

      <div className="flex flex-col md:flex-row gap-8 justify-center">
        <div
          className="border border-black w-[282px] h-[34px] p-3 flex items-center justify-between text-xs"
          style={{ letterSpacing: 2 }}
        >
          <span>Categoría</span>
          <button className="text-black">+</button>
        </div>
        <div
          className="border border-black w-[282px] h-[34px] p-3 flex items-center justify-between text-xs"
          style={{ letterSpacing: 2 }}
        >
          <span>Color</span>
          <button className="text-black">+</button>
        </div>
        <div
          className="border border-black w-[282px] h-[34px] p-3 flex items-center justify-between text-xs"
          style={{ letterSpacing: 2 }}
        >
          <span>Precio</span>
          <button className="text-black">+</button>
        </div>
        <div
          className="border border-black w-[282px] h-[34px] p-3 flex items-center justify-between text-xs"
          style={{ letterSpacing: 2 }}
        >
          <span>Talla</span>
          <button className="text-black">+</button>
        </div>
      </div>

      <h2
        className="font-regular py-[28px] text-[8px] px-3 uppercase 2xl:mx-[130px]"
        style={{ letterSpacing: 3 }}
      >
        Categorías de items
      </h2>

      <div className="flex flex-wrap justify-center gap-8">
        {/* <CardProduct
          image1={exampleProduct1}
          image2={exampleProduct2}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct2}
          image2={exampleProduct3}
          text={"Nombre de prenda"}
          price={"S/180.00"}
          priceOut={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct3}
          image2={exampleProduct4}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct4}
          image2={exampleProduct1}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct1}
          image2={exampleProduct2}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct2}
          image2={exampleProduct3}
          text={"Nombre de prenda"}
          price={"S/180.00"}
          priceOut={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct3}
          image2={exampleProduct4}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct4}
          image2={exampleProduct1}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct2}
          image2={exampleProduct3}
          text={"Nombre de prenda"}
          price={"S/180.00"}
          priceOut={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct3}
          image2={exampleProduct4}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct4}
          image2={exampleProduct1}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct4}
          image2={exampleProduct1}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        /> */}
      </div>
    </section>
  );
};

export default CategoryWoman;
