import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { getCountryFromLocation } from "./components/utils/Geolocation";
import { useCurrencySweet } from "./services/contexts/useCurrencySweet";
import { useRequestContext } from "./services/contexts/useContextSweet";
import "./fonts/fonts.css";
import "./App.css";
import { router } from "./routes";
import FullScreenLoaderNew from "./components/utils/FullScreenLoaderNew";
function App() {
  useEffect(() => {
    const fetchGeolocation = async () => {
      try {
        const location = await getCountryFromLocation();
        console.warn(location);
        console.log(
          `Tu ubicación es \n País: ${location.country_name}\n Capital: ${location.country_capital}`
        );

        // establishmentList[0].latitud = -12.046374;
        // establishmentList[0].longitud = -77.042793;
        //
        // establishmentList[1].latitud = -40.900557;
        // establishmentList[1].longitud = 174.885971;
        //
        // const exist = establishmentList.some(
        //   (establishment) => establishment.country.id === location.country_code,
        // );
        // if (exist) {
        //   const currencyInfo = getCurrencyInfo(location.country_code);
        //   if (currencyInfo) {
        //     const { code, symbol } = currencyInfo;
        //     localStorage.setItem(
        //       "currencyInfo",
        //       JSON.stringify({
        //         ...currencyInfo,
        //         country_name: location.country_name,
        //       }),
        //     );
        //     console.log(
        //       `Para ${location.country_code} la moneda es ${code} y su símbolo es ${symbol}`,
        //     );
        //     setCurrencyInformation({ ...location, code, symbol, local: true });
        //   } else {
        //     console.log(`No se encontró información para el código de país`);
        //   }
        // } else {
        //   localStorage.setItem(
        //     "currencyInfo",
        //     JSON.stringify({
        //       code: "USD",
        //       symbol: "$",
        //       country_name: location.country_name,
        //     }),
        //   );
        //   setCurrencyInformation({
        //     ...location,
        //     code: "USD",
        //     symbol: "$",
        //     local: false,
        //   });
        //   console.log(
        //     `Ahora no hay almacén para el país ${location.country_name}, tu moneda es USD y su símbolo es $ `,
        //   );
        // }

        //
        // establishmentList.forEach((tienda) => {
        //   const distancia = calculateDistance(
        //     location.latitude,
        //     location.longitude,
        //     tienda.latitud,
        //     tienda.longitud,
        //   );
        //   if (distancia < distanciaMinima) {
        //     distanciaMinima = distancia;
        //     tiendaMasCercana = tienda;
        //   }
        // });
        // console.warn("Almacén más cercano: " + tiendaMasCercana?.description);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };
    return () => {
      fetchGeolocation();
    };
  }, []);
  return (
    <>
      <RouterProvider router={router} />
      <FullScreenLoaderNew />
      <Toaster
        position={"top-center"}
        toastOptions={{ className: "react-hot-toast" }}
      />
    </>
  );
}

export default App;
