// ProtectedRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/config/redux-persist";

const ProtectedRoute: React.FC = () => {
  const auth = useSelector((state: RootState) => state.User);

  if (!auth.token) {
    return <Navigate to="/auth" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
