import { Link } from "react-router-dom";
import FullScreenSection from "../../components/home/FullScreenSection";
import bannerImage from "../../assets/images/blog.jpg";
import boxExample from "../../assets/images/blog_example.jpg";

const BlogScreen = () => {
  return (
    <>
      <FullScreenSection
        urlImage={bannerImage}
        title="NUESTRO BLOG"
        positionTitle="center"
        positionFit={46}
      />
      <div className="mt-16 text-center text-3xl font-normal tracking-[3px]">
        BLOG
      </div>
      <div className="flex justify-center mt-10 pb-28">
        <div className="w-full max-w-[1086px] h-auto lg:h-[271px] border-primaryColor border-2 flex flex-col lg:flex-row p-3">
          <div className="flex-shrink-0 lg:w-[443px] content-center lg:h-[218px] w-full h-auto">
            <img
              className="w-full h-auto object-cover"
              src={boxExample}
              alt="Descripción de la imagen"
            />
          </div>

          <div className="flex flex-col justify-center flex-1 p-4 lg:text-left mt-4 lg:mt-0">
            <h2 className="text-2xl" style={{ letterSpacing: 2 }}>
              La fibra de Alpaca: un camino sostenible hacia la moda
            </h2>
            <p
              className="mt-2 text-xs font-light tracking-widest"
              style={{ letterSpacing: 2 }}
            >
              La industria de la moda vive hoy un profundo momento de cambio. Si
              antes lo más importante era innovar en diseño y estilo, hoy el
              desafío es estructural y tiene que ver con la forma en la cual
              nuestro arte se relaciona con el entorno...
            </p>
            <div className="mt-4 text-center lg:text-left">
              <Link
                to="/blog-screen-details"
                className="inline-block uppercase bg-black text-white text-xs py-2.5 px-5"
              >
                Seguir leyendo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogScreen;
