// userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientProfile, ProfileResponse } from '../services/clientService';

interface UserState {
    email: string;
    token: string;
    user: ClientProfile;
    isAuthenticated: boolean;
}



const initialState: UserState = {
    email: '',
    token: '',
    user: {
        "id": 0,
        "firstName": "",
        "lastName": "",
        "email": "",
        "telephone": null,
        "country": null,
        "address": null,
        "idGoogle": null,
        "idFacebook": null,
        "resetToken": null,
        "deletedAt": null,
        "createdAt": "",
        "updateAt": ""
    },
    isAuthenticated: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<{ email: string; token: string }>) => {
            state.email = action.payload.email;
            state.token = action.payload.token;
            state.isAuthenticated = true;
        },
        profileSuccess: (state, action: PayloadAction<ProfileResponse>) => {
            state.user = action.payload.data;
        },
        logout: (state) => {
            state.email = '';
            state.token = '';
            state.isAuthenticated = false;
        },
    },
});

export const { loginSuccess, profileSuccess, logout } = userSlice.actions;
export default userSlice.reducer;
