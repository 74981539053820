import React, { useState } from "react";
import { Link } from "react-router-dom";
import imageWorkshopReturn from "../../assets/images/workshop_returns.jpg";
import imageCourierReturn from "../../assets/images/courier_Returns.jpg";

const ReturnsExchanges = () => {
  const [showWashed, setShowWashed] = useState<boolean>(false);
  const [showIroning, setShowIroning] = useState<boolean>(false);
  const [showDryCleaning, setShowDryCleaning] = useState<boolean>(false);

  const toggleWashed = () => {
    setShowWashed(!showWashed);
  };

  const toggleIroning = () => {
    setShowIroning(!showIroning);
  };

  const toggleDryCleaning = () => {
    setShowDryCleaning(!showDryCleaning);
  };

  return (
    <div className="text-primaryColor" style={{ letterSpacing: 3 }}>
      <h2 className="uppercase flex justify-center text-center text-3xl mt-12 text-primaryColor">
        POLÍTICA Y DEVOLUCIÓN DE ILAINI
      </h2>

      <main className="lg:px-20 container text-primaryColor py-16">
        <div className="mt-20 x1280:px-32 text-primaryColor">
          <div
            className="flex space-y-2 items-center justify-between"
            onClick={toggleWashed}
          >
            <h2 className="uppercase text-2xl">NUESTRA POLÍTICA DE 15 DÍAS</h2>
            <button className="text-2xl">{showWashed ? "-" : "+"}</button>
          </div>

          <div className="border-t-2 border-black opacity-50 py-3 " />

          {showWashed && (
            <>
              <p className="text-xs px-3" style={{ letterSpacing: 2 }}>
                El cliente dispondrá de 15 días calendarios a partir de la
                entrega del producto, para generar el cambio.
              </p>
            </>
          )}
        </div>
        <div className="mt-4 x1280:px-32 text-primaryColor">
          <div
            className="flex space-y-2 items-center justify-between"
            onClick={toggleIroning}
          >
            <h2 className="uppercase text-2xl" style={{ letterSpacing: 2 }}>
              CONDICIONES DE LA PRENDA
            </h2>
            <button className="text-2xl">{showIroning ? "-" : "+"}</button>
          </div>

          <div className="border-t-2 border-black opacity-50 py-3 " />
          {showIroning && (
            <p className="text-xs px-3" style={{ letterSpacing: 2 }}>
              El producto debe presentarse en las mismas condiciones que en la
              copmrpa y con los artículos que incluye (etiquetas originales
              brindadas por la marca)
            </p>
          )}
        </div>

        <div className="mt-4 x1280:px-32 text-primaryColor">
          <div
            className="space-y-2 flex items-center justify-between"
            onClick={toggleDryCleaning}
          >
            <h2 className="uppercase text-2xl " style={{ letterSpacing: 2 }}>
              TÍTULAR DE COMPRA
            </h2>
            <button className="text-2xl">{showDryCleaning ? "-" : "+"}</button>
          </div>

          <div className="border-t-2 border-black opacity-50 py-3 " />
          {showDryCleaning && (
            <>
              <p className="text-xs px-3" style={{ letterSpacing: 2 }}>
                Es necesario que el comprobante de pago (boleta o factura) ya
                sea de forma física o digital, sea presentado por el cliente
                titular de la compra.
              </p>
              <div className="bg-bgBoxFooter mt-10 px-10 py-10 space-y-2 text-base">
                <ol
                  className="px-6 list-decimal text-xs text-justify"
                  style={{ letterSpacing: 2 }}
                >
                  <li>El cambio estará sujeto a la disponibilidad de stock.</li>
                  <li className="mt-4">
                    El costo del envío y reenvío estarán a cargo del cliente,
                    excepto cuando se trate de un cambio por defecto de prenda,
                    en tal caso, Ilaini cubrirá dichos cargos.
                  </li>
                  <li className="mt-4">
                    En caso que el cliente se encuentre en la ciudad de
                    Arequipa, el cliente puede realizar el cambio en nuestra
                    oficina ubicada en Yanahuara.
                  </li>
                </ol>
              </div>
              <div className="my-12 x1280:px-32 text-primaryColor">
                <p
                  className="text-[15px] font-bold text-center"
                  style={{ letterSpacing: 3 }}
                >
                  NO SE ACEPTAN DEVOLUciONES DE PRENDAS O DINERO
                </p>
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default ReturnsExchanges;
