import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

interface BannerHomeProps {
  banner: string;
  positionFit?: number;
  title?: string;
  positionTitle?: string;
  textButton?: string;
  buttonLink?: string;
  paddingBottom?: number;
}

const BannerHome: React.FC<BannerHomeProps> = ({
  banner,
  title,
  positionTitle = "end",
  textButton,
  buttonLink = "#",
  positionFit = 40,
  paddingBottom = 20,
}) => {
  const handleButtonClick = () => {
    if (buttonLink) {
      window.location.href = buttonLink;
    }
  };

  return (
    <div className="relative h-screen w-full bg-cover bg-center">
      <img
        src={banner}
        alt="Equilibrio Natural"
        className="absolute inset-0 w-full h-full"
        style={{
          objectPosition: positionFit + "% center",
          objectFit: "cover",
        }}
      />
      <p className="font-coldiac text-bgColor absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-5xl ">
        {title}
      </p>

      {textButton && (
        <div className="absolute top-3/4 left-1/2 transform -translate-x-1/2 translate-y-12">
          <button
            onClick={handleButtonClick}
            className="bg-white text-black py-2 px-8 text-xs hover:bg-gray-200 transition font-monserrat w-[286px] font-normal border border-gray-400 shadow-md hover:shadow-lg hover:opacity-90"
            style={{ whiteSpace: "nowrap" }}
          >
            {textButton}
          </button>
        </div>
      )}
    </div>
  );
};

export default BannerHome;
