import imageCorporateSale from "../../assets/images/corporate_sales.jpg";
import FullScreenSection from "../../components/home/FullScreenSection";

const CorporateSales = () => {
  return (
    <>
      <FullScreenSection
        urlImage={imageCorporateSale}
        title="VENTAS CORPORATIVAS"
        positionTitle="center"
        positionFit={46}
        textButton={"CONTÁCTANOS"}
        href={"/contact-us"}
      />
    </>
  );
};

export default CorporateSales;
