import "swiper/css";
import "swiper/css/navigation";
// import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { BannerItem } from "../../services/configService";
import { useNavigate } from "react-router-dom";
interface FullScreenSectionType {
  urlImage: string;
  positionFit?: number;
  title?: string;
  positionTitle?: string;
  textButton?: string;
  paddingBottom?: number;
  href?: string;
}
const FullScreenSection: React.FC<FullScreenSectionType> = ({
  urlImage,
  title,
  positionTitle = "end",
  textButton,
  positionFit = 40,
  paddingBottom = 20,
  href = "/",
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative h-screen w-full bg-cover bg-center">
        <img
          loading="lazy"
          src={urlImage}
          // width="1903"
          // height="896"
          alt="Pattern"
          className="absolute inset-0 w-full h-full "
          style={{
            objectPosition: positionFit + "% center",
            objectFit: "cover",
          }}
        />
        <div
          className={`absolute inset-0 flex flex-col justify-${positionTitle} items-center ${
            positionTitle == "end" && `pb-${paddingBottom}`
          }
        `}
        >
          {/* absolute inset-0 bg-opacity-50 flex flex-col justify-center items-center */}
          <h1 className="text-white text-5xl md:text-6xl mb-4 font-coldiac text-center">
            {title}
          </h1>
          {textButton && (
            <button
              className="font-normal bg-white text-black-900  py-[10px] px-[74px] rounded-full text-xl hover:bg-gray-200 transition font-monserrat rounded-none"
              onClick={() => navigate(href)}
            >
              {textButton}
            </button>
          )}
        </div>
      </div>
      <div className="pb-2 pt-1"></div>
    </>
  );
};
export default FullScreenSection;
