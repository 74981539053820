import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { ProductGeneral } from "../../services/productsService";

interface CardProductType {
  product: ProductGeneral;
  showPrice?: boolean;
  priceOut?: string;
  category?: string;
  color?: string;
  size?: string;
  buttonAdd?: string;
  buttonMore?: string;
}

const CardProductNew: React.FC<CardProductType> = ({
  product,
  showPrice = true,
  priceOut,
  buttonAdd,
  buttonMore,
  category,
  color,
  size,
}) => {
  return (
    <div className="md:w-[383px] w-[282px] md:h-[514px]  flex-shrink-0">
      <div className="pc__img-wrapper">
        <a href={"/product/" + product?.slug}>
          <img
            loading="lazy"
            src={product?.images[0]}
            alt={product?.name}
            className="pc__img"
          />
          <img
            loading="lazy"
            src={product?.images[1]}
            alt={product?.name}
            className="pc__img pc__img-second"
          />
        </a>
        <button
          className="pc__btn-wl absolute border-0 text-primary js-add-wishlist"
          title="Add To Wishlist"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <use href="#icon_heart"></use>
          </svg>
        </button>
      </div>
      <div className="pc__info relative mb-[21px]" style={{ letterSpacing: 3 }}>
        <h6 className="pc__title text-[10px]">
          <a href="./product1_simple.html">{product?.description}</a>
        </h6>
        <div className="w-full uppercase text-xs block w-full text-black text-left">
          {product?.name}
        </div>
      </div>
    </div>
  );
};

export default CardProductNew;
