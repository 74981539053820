import { myFetch, ErrorHandler, HttpMethod } from "./baseService";
import { apiRequest } from "./newBaseService";

interface GeneralResponse {
  message: string;
  data: Config;
}

interface SubscribeResponse {
  message: string;
  data: [];
}

export interface Config {
  general: GeneralItem;
  socials: SocialItem[];
  banners: BannerItem[];
}

export interface GeneralItem {
  data: {
    id: number;
    logoPath: string;
    modalPath: string;
    section: string;
    email: string;
    number: number;
    telephone: number;
    address: string;
    publishModal: boolean;
    publishSection: boolean;
    updateAt: string;
    createdAt: string;
    deletedAt: null | string;
  };
}

export interface SocialItem {
  id: number;
  name: string;
  url: string;
  publish: boolean;
  updateAt: string;
  createdAt: string;
  deletedAt: null | string;
}

export interface BannerItem {
  id: number;
  title: string;
  description: string;
  offer: string;
  imagePath: string;
  publish: boolean;
  updateAt: string;
  createdAt: string;
  deletedAt: null | string;
}
type GeneralResponseHandler = (data: GeneralResponse) => void;

export const configuration = async (
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "shoppings/configuration",
    "",
    resultHandler,
    errorHandler,
    HttpMethod.GET
  );

export const sendEmailSubscribe = async (
  email: object
): Promise<SubscribeResponse> => {
  try {
    const response = await apiRequest<SubscribeResponse>(
      "client/auth/subscribe",
      {
        body: JSON.stringify(email),
        method: "POST",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(`Error fetching collection products: ${error.message}`);
  }
};

export const sendContactForm = async (
  request: object
): Promise<SubscribeResponse> => {
  try {
    const response = await apiRequest<SubscribeResponse>(
      "client/auth/contactForm",
      {
        body: JSON.stringify(request),
        method: "POST",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(`Error fetching collection products: ${error.message}`);
  }
};
