import { RootState, store } from "../store/config/redux-persist";
import { myFetch, ErrorHandler, HttpMethod } from "./baseService";
import { apiRequest } from "./newBaseService";

export const getAuthHeaders = (): { [key: string]: string } => {
  const state = store.getState();
  const token = state.User.token;
  return {
    Authorization: `Bearer ${token}`,
  };
};
export interface Client {
  firstName?: string;
  lastName?: string;
  email: string;
  password?: string;
}
export interface ClientProfile {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string | null;
  country: string | null;
  address: string | null;
  idGoogle: string | null;
  idFacebook: string | null;
  resetToken: string | null;
  deletedAt: string | null;
  createdAt: string;
  updateAt: string;
}

export interface Login {
  email: string;
  token: string;
}
export type GeneralResponse = {
  message: string;
  data: Client;
};
export type LoginResponse = {
  message: string;
  data: Login;
};
export type ProfileResponse = {
  message: string;
  data: ClientProfile;
};
type GeneralResponseHandler = (data: GeneralResponse) => void;

export const getToken = async (
  form: { code: string | null },
  resultHandler: any,
  errorHandler: ErrorHandler
) =>
  myFetch<{
    message: string;
    data: { token: string };
  }>(
    "client/auth/google/getToken",
    JSON.stringify(form),
    resultHandler,
    errorHandler,
    HttpMethod.POST
  );
export const register = async (
  form: Client,
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "client/auth/register",
    JSON.stringify(form),
    resultHandler,
    errorHandler,
    HttpMethod.POST
  );

export const login = (form: Client): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "client/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(form),
        }
      );

      const data = await response.json();

      if (response.ok) {
        resolve(data);
      } else {
        reject(new Error(data.message || "Login failed"));
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getClientToken = async (): Promise<any> => {
  try {
    const response = await apiRequest<any>(`client/auth/profile`, {
      method: "GET",
      headers: {
        ...getAuthHeaders(),
      },
    });
    return response;
  } catch (error: any) {
    throw new Error(`Error fetching: ${error.message}`);
  }
};
