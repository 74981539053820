import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductGeneral } from '../services/productsService';

interface CartState {
    products: ProductGeneral[];
}

const initialState: CartState = {
    products: [],
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<ProductGeneral>) => {
            const productIndex = state.products.findIndex((item) =>
                item.id === action.payload.id &&
                item.selectedImage === action.payload.selectedImage &&
                item.selectedSize === action.payload.selectedSize &&
                item.selectedColor === action.payload.selectedColor
            );

            if (productIndex >= 0) {
                // Si el producto existe, actualiza la cantidad
                const existingQuantity = state.products[productIndex].quantity ?? 0;
                const payloadQuantity = action.payload.quantity ?? 1;
                state.products[productIndex].quantity = existingQuantity + payloadQuantity;
            } else {
                // Si el producto no existe, agrega como nuevo
                state.products.push({ ...action.payload, quantity: action.payload.quantity ?? 1 });
            }
        },
        removeFromCart: (state, action: PayloadAction<number>) => {
            state.products = state.products.filter((item) => item.id !== action.payload);
        },
        clearCart: (state) => {
            state.products = [];
        },
    },
});


export const { addToCart, removeFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
