import { createBrowserRouter } from "react-router-dom";
import OurHistory from "./pages/us/OurHistory";
import PrivacyPolicy from "./pages/us/PrivacyPolicy";
import TermsUse from "./pages/us/TermsUse";
import CorporateSales from "./pages/us/CorporateSales";
import Contact from "./pages/customerService/Contact";
import Shipping from "./pages/customerService/Shipping";
import ReturnsExchanges from "./pages/customerService/ReturnsExchanges";
import SizeGuide from "./pages/customerService/SizeGuide";
import ClothingCare from "./pages/customerService/ClothingCare";
import ProductDetail from "./pages/product/ProductDetail";
import Sustainability from "./pages/us/Sustainability";
import HomeAlternative from "./pages/HomeAlternative";
import BlogScreen from "./pages/us/BlogScreen";
import BlogScreenDetails from "./pages/us/BlogScreenDetails";
import Sale from "./pages/category/Sale";
import NewSeason from "./pages/category/NewSeason";
import Profile from "./pages/Profile";
import CategoryWoman from "./pages/category/CategoryWoman";
import CategoryMan from "./pages/category/CategoryMan";
import CategoryKids from "./pages/category/CategoryKids";
import SectionProducts from "./pages/product/SectionProducts";
import Layout from "./components/layouts/Layout";
import FiberCatalog from "./pages/us/FiberCatalog";
import AuthRedirect from "./pages/AuthRedirect";
import FindUs from "./pages/us/FindUs";
import Register from "./pages/client/Auth";
import AuthScreen from "./pages/client/Auth";
import ProtectedRoute from "./components/utils/ProtectedRoute";
import NotFound from "./pages/NotFound";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <HomeAlternative />,
      },
    ],
  },
  {
    path: "/blogs",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <BlogScreen />,
      },
    ],
  },
  {
    path: "/blog/:name",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <BlogScreenDetails />,
      },
    ],
  },
  {
    path: "/fiber-catalog",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <FiberCatalog />,
      },
    ],
  },
  {
    path: "/auth-redirect",
    element: <AuthRedirect />,
  },
  {
    path: "/contact",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <FindUs />,
      },
    ],
  },
  {
    path: "/find-us",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <FindUs />,
      },
    ],
  },
  {
    path: "/our-history",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <OurHistory />,
      },
    ],
  },
  {
    path: "/sustainability",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Sustainability />,
      },
    ],
  },
  {
    path: "/privacy-policy",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: "/terms-of-use",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <TermsUse />,
      },
    ],
  },
  {
    path: "/corporate-sales",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <CorporateSales />,
      },
    ],
  },
  {
    path: "/contact-us",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Contact />,
      },
    ],
  },
  {
    path: "/shipments",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Shipping />,
      },
    ],
  },
  {
    path: "/returns",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ReturnsExchanges />,
      },
    ],
  },
  {
    path: "/size-guide",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <SizeGuide />,
      },
    ],
  },
  {
    path: "/garment-care",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ClothingCare />,
      },
    ],
  },
  {
    path: "/product/:nameProduct",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ProductDetail />,
      },
    ],
  },
  {
    path: "/section/:nameSection",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <SectionProducts />,
      },
    ],
  },
  {
    path: "/category-man",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <CategoryMan />,
      },
    ],
  },
  {
    path: "/category-woman",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <CategoryWoman />,
      },
    ],
  },
  {
    path: "/category-kids",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <CategoryKids />,
      },
    ],
  },
  {
    path: "/sale",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Sale />,
      },
    ],
  },
  {
    path: "/new-season",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <NewSeason />,
      },
    ],
  },

  /* Private Routes */
  {
    path: "/profile",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute />,
        children: [
          {
            index: true,
            element: <Profile />,
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <AuthScreen />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound /> ,
  },
]);
