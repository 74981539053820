import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

interface CardProductType {
  image: string;
  name: string;
  color: string;
  amount: string;
  price: string;
}

const CardOrders: React.FC<CardProductType> = ({
  image,
  name,
  color,
  amount,
  price,
}) => {
  return (
    <div>
      <div className="flex">
        <img
          loading="lazy"
          src={image}
          width="197"
          height="254.46"
          alt="OrderImg"
        />
        <div className="ml-4">
          <div style={{ letterSpacing: 3 }}>
            <p className="text-[10px] font-bold">{name}</p>
            <p className="text-[10px] mt-8">{color}</p>
            <p className="text-[10px]">x{amount}</p>
            <p
              className="flex mt-28 font-bold text-[10px]"
              style={{ letterSpacing: 3 }}
            >
              Total:
              <p className="ml-2">S/{price}</p>
            </p>
          </div>
          <div className="mt-4 bg-black w-[147px] h-[34px] flex items-center justify-center">
            <p
              className="uppercase text-white text-[10px]"
              style={{ letterSpacing: 2 }}
            >
              Volver a pedir
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOrders;
