import React, { useState, useEffect } from "react";
import exampleProduct1 from "../../assets/images/example_product1.jpg";
import exampleProduct2 from "../../assets/images/example_product2.jpg";
import exampleProduct3 from "../../assets/images/example_product3.jpg";
import exampleProduct4 from "../../assets/images/example_product4.jpg";
import CardProduct from "../../components/home/CardProduct";
import { Link } from "react-router-dom";

const NewSeason = () => {
  const [expanded, setExpanded] = useState<{
    category: boolean;
    color: boolean;
    price: boolean;
    size: boolean;
  }>({
    category: false,
    color: false,
    price: false,
    size: false,
  });

  const toggleExpand = (section: "category" | "color" | "price" | "size") => {
    setExpanded((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <section className="product-single container">
      <nav
        className="font-regular mt-6 text-[8px] uppercase px-3 2xl:mx-[130px]"
        style={{ letterSpacing: 3 }}
      >
        <Link to="/" className="hover:underline">
          Inicio
        </Link>{" "}
        &gt;
        <Link to="/newseason" className="hover:underline ml-2">
          Nueva temporada
        </Link>
      </nav>

      <div className="items-center justify-center px-3 2xl:mx-[130px] my-8">
        <h1 className="text-5xl font-coldiac">ILAINI: EQUILIBRIO NATURAL</h1>
        <p className="text-xs mt-6 font-regular" style={{ letterSpacing: 2 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nec
          elementum diam. Integer cursus arcu in tempor volutpat. In odio orci,
          tempor id fringilla at, commodo quis ligula. Cras elementum pharetra
          fringilla. Duis neque est, vestibulum a tempor in, egestas sit amet
          massa. Ut condimentum dolor sit amet urna tempor pellentesque. Morbi
          sed semper urna. Aliquam rhoncus laoreet sodales. Suspendisse sit amet
          nisl quis libero placerat aliquet vitae id ipsum. In at urna
          hendrerit, ullamcorper ipsum id, tincidunt ex. Quisque vitae laoreet
          sem. Etiam enim turpis, posuere vitae leo at, tincidunt ullamcorper
          lectus. Maecenas consequat, risus at dignissim tincidunt, ex magna
          accumsan ante, ut blandit lectus ex ut ex. Sed ultricies ipsum
          convallis bibendum congue. Proin ut enim eu ante interdum finibus a
          sit amet mauris.
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-8 justify-center px-3">
        <div className="w-full">
          <div
            className="border border-black p-3 flex items-center justify-between text-xs cursor-pointer"
            style={{ letterSpacing: 2 }}
            onClick={() => toggleExpand("category")}
          >
            <span>Categoría</span>
            <button className="text-black">
              {expanded.category ? "-" : "+"}
            </button>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              expanded.category ? "max-h-40" : "max-h-0"
            }`}
          >
            <div className="border p-3 text-xs" style={{ letterSpacing: 2 }}>
              <p className="py-2">Opción 1</p>
              <p className="py-2">Opción 2</p>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div
            className="border border-black p-3 flex items-center justify-between text-xs cursor-pointer"
            style={{ letterSpacing: 2 }}
            onClick={() => toggleExpand("color")}
          >
            <span>Color</span>
            <button className="text-black">{expanded.color ? "-" : "+"}</button>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              expanded.color ? "max-h-40" : "max-h-0"
            }`}
          >
            <div className="border p-3 text-xs" style={{ letterSpacing: 2 }}>
              <p className="py-2">Rojo</p>
              <p className="py-2">Azul</p>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div
            className="border border-black p-3 flex items-center justify-between text-xs cursor-pointer"
            style={{ letterSpacing: 2 }}
            onClick={() => toggleExpand("price")}
          >
            <span>Precio</span>
            <button className="text-black">{expanded.price ? "-" : "+"}</button>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              expanded.price ? "max-h-40" : "max-h-0"
            }`}
          >
            <div className="border p-3 text-xs" style={{ letterSpacing: 2 }}>
              <p className="py-2">Menos de S/100</p>
              <p className="py-2">Más de S/100</p>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div
            className="border border-black p-3 flex items-center justify-between text-xs cursor-pointer"
            style={{ letterSpacing: 2 }}
            onClick={() => toggleExpand("size")}
          >
            <span>Talla</span>
            <button className="text-black">{expanded.size ? "-" : "+"}</button>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              expanded.size ? "max-h-40" : "max-h-0"
            }`}
          >
            <div className="border p-3 text-xs" style={{ letterSpacing: 2 }}>
              <p className="py-2">s</p>
              <p className="py-2">m</p>
              <p className="py-2">l</p>
              <p className="py-2">xl</p>
            </div>
          </div>
        </div>
      </div>
      <h2
        className="font-regular py-[28px] px-3 text-[8px] uppercase 2xl:mx-[130px]"
        style={{ letterSpacing: 3 }}
      >
        Categorías de items
      </h2>

      <div className="flex flex-wrap justify-center gap-8">
        {/* <CardProduct
          image1={exampleProduct1}
          image2={exampleProduct2}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct2}
          image2={exampleProduct3}
          text={"Nombre de prenda"}
          price={"S/180.00"}
          priceOut={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct3}
          image2={exampleProduct4}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct4}
          image2={exampleProduct1}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct1}
          image2={exampleProduct2}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct2}
          image2={exampleProduct3}
          text={"Nombre de prenda"}
          price={"S/180.00"}
          priceOut={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct3}
          image2={exampleProduct4}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        />
        <CardProduct
          image1={exampleProduct4}
          image2={exampleProduct1}
          text={"Nombre de prenda"}
          price={"S/200.00"}
        /> */}
      </div>
    </section>
  );
};

export default NewSeason;
