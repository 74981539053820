import React, { useState } from "react";
import Banner from "../../components/common/Banner";
import imageSize from "../../assets/images/size_guide.jpg";
import sizeGuideWoman from "../../assets/images/sizeGuideWoman.png";
import FullScreenSection from "../../components/home/FullScreenSection";
const SizeGuide = () => {
  const [showWoman, setShowWoman] = useState<boolean>(false);
  const [showMen, setShowMen] = useState<boolean>(false);
  const [showKids, setShowKids] = useState<boolean>(false);

  const toggleWoman = () => {
    setShowWoman(!showWoman);
  };

  const toggleMen = () => {
    setShowMen(!showMen);
  };

  const toggleKids = () => {
    setShowKids(!showKids);
  };

  return (
    <>
      {/* <Banner banner={imageSize} text={"Guía de Tallas"} /> */}
      <FullScreenSection
        urlImage={imageSize}
        title="GUIA DE TALLAS"
        positionTitle="center"
        positionFit={46}
      />

      <div className="text-primaryColor py-10 md:px-0 px-6">
        <h2
          className="flex justify-center text-primaryColor font-monserrat text-3xl uppercase"
          style={{ letterSpacing: 3 }}
        >
          Guía de tallas
        </h2>
        <div className="pt-12 space-y-2 x1280:px-44">
          <div
            className="flex items-center justify-between"
            onClick={toggleWoman}
          >
            <h2 className="uppercase text-2xl " style={{ letterSpacing: 2 }}>
              Mujer
            </h2>
            <button className="text-2xl">{showWoman ? "-" : "+"}</button>
          </div>
          <div className="border-t border-primaryColor" />
          {showWoman && (
            <div className="flex justify-center py-2">
              <img src={sizeGuideWoman} alt="" className="" />
            </div>
          )}
          <div
            className="mt-4 flex items-center justify-between"
            onClick={toggleMen}
          >
            <h2 className="uppercase text-2xl" style={{ letterSpacing: 2 }}>
              Hombre
            </h2>
            <button className="text-2xl">{showMen ? "-" : "+"}</button>
          </div>
          <div className="border-t border-primaryColor" />
          {showMen && (
            <div className="flex justify-center py-2">
              <img src={sizeGuideWoman} alt="" className="" />
            </div>
          )}
          <div
            className="mt-4 flex items-center justify-between"
            onClick={toggleKids}
          >
            <h2 className="uppercase text-2xl" style={{ letterSpacing: 2 }}>
              Niños
            </h2>
            <button className="text-2xl">{showKids ? "-" : "+"}</button>
          </div>
          <div className="border-t border-primaryColor" />
          {showKids && (
            <div className="flex justify-center py-2">
              <img src={sizeGuideWoman} alt="" className="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SizeGuide;
