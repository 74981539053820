import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

interface CardSmallType {
  image: string;
  title?: string;
  titleUp?: string;
}

const CardSmall: React.FC<CardSmallType> = ({ image, title, titleUp }) => {
  return (
    <>
      <div className="uppercase w-[271px] h-[271px] border-primaryColor border-2 flex flex-col justify-between p-3 text-15px">
        <h2 className="text-center">{titleUp}</h2>
        <div className="flex justify-center items-center h-full">
          <img
            src={image}
            alt="lanaMerino"
            className="w-[127px] h-[127px] object-cover"
          />
        </div>
        <div className="mt-auto bg-black py-2 text-center">
          <p className="uppercase text-white text-xs">{title}</p>
        </div>
      </div>
    </>
  );
};
export default CardSmall;
