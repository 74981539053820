import React from "react";

const TermsUse = () => {
  return (
    <>
      <hr />
      {/* <div className="text-primaryColor">
          <h2
            className="flex justify-center text-primaryColor text-3xl uppercase"
            style={{ letterSpacing: 3 }}
          >
            Términos de Uso
          </h2>
          <div className="x1280:px-52 mt-16 text-xs font-monserrat font-light tracking-wider2 text-justify text-primaryColor text-justify">
            <p>
              The website www.seedheritage.com and www.seedheritage.com/nz and
              the associated applications (“Site") is owned and operated
            </p>
            <p>
              by Steambrook Pty Ltd (ABN 63 114 765 703) (t/as and referred as
              Seed Heritage, "we", "us").
            </p>
            <p className="mt-4">
              Your use of this Website is governed by the terms set out below:
            </p>
            <p>- Legal Notice;</p>
            <p>- Privacy Policy;</p>
            <p>
              - and any and all other terms or conditions on this Website
              ("Terms of Use").
            </p>
            <p className="mt-4">
              By accessing or using this Website, you agree to the Terms of Use.
            </p>
            <p className="mt-4">Legal Notice</p>
            <p>
              With the exception of laws relating to customs and to taxes and
              duties on imported goods, the parties agree that the laws of the
              State of Victoria, Australia apply to your use of Seed Heritage
              Online (including Seed Heritage Online NZ).
            </p>
            <p className="mt-4">
              The parties submit to the exclusive jurisdiction of the courts of
              Victoria in relation to any dispute in relation to the sale and
              purchase of goods.
            </p>
          </div>
        </div> */}
      <div className="max-w-4xl mx-auto my-10 p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Términos y Condiciones
        </h1>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            1. Información General
          </h2>
          <p className="text-gray-600 text-justify">
            La tienda virtual Ilaini, con sede en Arequipa, Perú, ofrece
            productos para venta en línea con alcance a todas las provincias del
            país. Estos términos y condiciones rigen el uso de nuestro sitio web
            y la relación con nuestros clientes.
          </p>
          <p className="text-gray-600 mt-2  text-justify">
            Al realizar una compra en Ilaini, usted acepta estos términos y
            condiciones en su totalidad.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            2. Registro de Usuarios
          </h2>
          <p className="text-gray-600 text-justify">
            Para realizar compras, el cliente debe registrarse proporcionando
            datos personales veraces y actualizados. Ilaini no se hace
            responsable por problemas derivados de información incorrecta o
            desactualizada proporcionada por el cliente.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            3. Precios y Disponibilidad de Productos
          </h2>
          <p className="text-gray-600 text-justify">
            Todos los precios indicados en nuestro sitio web están en soles
            peruanos (PEN) e incluyen el Impuesto General a las Ventas (IGV),
            salvo que se indique lo contrario. La disponibilidad de productos
            está sujeta a cambios sin previo aviso. Nos reservamos el derecho de
            limitar la cantidad de productos disponibles para la venta.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            4. Proceso de Compra y Pago
          </h2>
          <p className="text-gray-600 text-justify">
            Las compras pueden realizarse a través de nuestro sitio web usando
            los métodos de pago disponibles: tarjeta de crédito, tarjeta de
            débito y transferencias bancarias. Los pedidos se confirmarán una
            vez que se haya verificado el pago correspondiente.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            5. Envíos y Entregas
          </h2>
          <p className="text-gray-600 text-justify">
            Realizamos envíos a todo el Perú desde nuestra sede en Arequipa. Los
            tiempos de entrega varían según la ubicación y el método de envío
            seleccionado. Los costos de envío se calculan durante el proceso de
            compra y dependerán de la ubicación de entrega.
          </p>
          <p className="text-gray-600 mt-2 text-justify">
            Es responsabilidad del cliente proporcionar una dirección de entrega
            completa y precisa. Ilaini no se hace responsable de retrasos o
            inconvenientes derivados de información incorrecta.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            6. Cambios, Devoluciones y Reembolsos
          </h2>
          <p className="text-gray-600 text-justify">
            El cliente tiene un plazo de 7 días hábiles desde la recepción del
            producto para solicitar un cambio o devolución, siempre y cuando el
            producto se encuentre en su empaque original, sin uso y con la
            boleta de compra correspondiente.
          </p>
          <p className="text-gray-600 mt-2 text-justify">
            Los costos de envío en caso de cambios o devoluciones corren a
            cuenta del cliente, a menos que el producto recibido sea defectuoso
            o incorrecto.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            7. Políticas de Privacidad
          </h2>
          <p className="text-gray-600 text-justify">
            La información personal proporcionada por el cliente será tratada
            conforme a la Ley N° 29733, Ley de Protección de Datos Personales de
            Perú. Los datos recopilados se utilizarán exclusivamente para
            procesar pedidos y mejorar la experiencia de compra.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            8. Modificaciones a los Términos y Condiciones
          </h2>
          <p className="text-gray-600 text-justify">
            Ilaini se reserva el derecho de modificar estos términos y
            condiciones en cualquier momento. Las modificaciones se publicarán
            en nuestro sitio web y serán efectivas desde el momento de su
            publicación.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            9. Contacto
          </h2>
          <p className="text-gray-600 text-justify">
            Para consultas, reclamos o soporte, puede contactarnos a través de
            nuestro sitio web o al correo electrónico de servicio al cliente:
            <a
              href="mailto:contacto@ilaini.com"
              className="text-indigo-500 hover:underline"
            >
              {" "}
              contacto@ilaini.com
            </a>
            .
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            10. Legislación Aplicable y Jurisdicción
          </h2>
          <p className="text-gray-600">
            Estos términos y condiciones se rigen por las leyes peruanas.
            Cualquier controversia o conflicto derivado del uso del sitio web de
            Ilaini o de la compra de productos se someterá a los tribunales
            competentes de Arequipa, Perú.
          </p>
        </section>
      </div>
    </>
  );
};

export default TermsUse;
