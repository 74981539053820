import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CardOrders from "../components/home/CardOrders";
import exampleProduct1 from "../assets/images/example_product1.jpg";

const Profile = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const handleToggle = (section: string) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const sections = [
    { name: "Perfil", id: "perfil" },
    { name: "Dirección", id: "direccion" },
    { name: "Pedidos", id: "pedidos" },
    { name: "Lista de deseos", id: "lista-deseos" },
    { name: "Cerrar sesión", id: "cerrar-sesion" },
  ];

  return (
    <section className="product-single container mx-auto">
      <nav
        className="font-regular mt-6 text-[8px] uppercase px-3 xl:px-[132px]"
        style={{ letterSpacing: 3 }}
      >
        <Link to="/" className="hover:underline">
          Inicio
        </Link>{" "}
        &gt;
        <Link to="/profile" className="hover:underline ml-2">
          Mi cuenta
        </Link>
      </nav>

      <h1
        className="text-3xl uppercase text-center mb-8"
        style={{ letterSpacing: 2 }}
      >
        Mi cuenta
      </h1>
      <div className="flex flex-col xl:flex-row lg:px-[132px]">
        {/* Sección de Opciones */}
        <div className="w-full xl:w-1/3">
          {sections.map((section, index) => (
            <div
              key={section.id}
              className={`text-sm ${index === 0 ? "border-t-0" : "border-t"} ${
                activeSection === section.id ? "opacity-100" : "opacity-50"
              } border-black`}
              style={{ letterSpacing: 1 }}
            >
              <button
                onClick={() => handleToggle(section.id)}
                className="w-full flex justify-between items-center my-2.5"
              >
                {section.name}
                <span className="text-sm">
                  {activeSection === section.id ? "-" : "+"}
                </span>
              </button>
              {/* Línea debajo de cada sección, excepto la última */}
              {index < sections.length - 1 && (
                <div className="border-b border-black" />
              )}
            </div>
          ))}
        </div>

        {/* Línea de separación, oculta en pantallas móviles */}
        <div
          className="hidden xl:block border-l border-[#171717] border-opacity-50 mx-12"
          style={{ height: "auto", marginLeft: "50px" }}
        ></div>

        {/* Sección de Detalles */}
        <div className="w-full xl:w-2/3 p-4 xl:mt-0">
          {activeSection === "perfil" && (
            <div>
              <div
                className="text-xs flex items-center"
                style={{ letterSpacing: 2 }}
              >
                <p>Hola, Usuario97 (¿no eres Usuario97?)</p>
                <p className="underline ml-2 ">Cierra Sesión</p>
              </div>
              <div
                className="text-[10px] bg-[#E3E3E3] flex items-center mt-3"
                style={{ letterSpacing: 2 }}
              >
                <p className="underline font-bold">
                  ¡Haz tu primera compra ahora!
                </p>
                <p className="ml-2">Aún no tienes compras registradas.</p>
              </div>
              <p
                className="text-xs font-bold uppercase mt-10"
                style={{ letterSpacing: 2 }}
              >
                Detalles de la cuenta
              </p>
              <div
                className="text-xs mt-6 flex items-center justify-between"
                style={{ letterSpacing: 2 }}
              >
                <p className="text-bold">Nombre</p>
                <p>Usuario97</p>
              </div>
              <div className="border-b border-black my-2.5" />
              <div
                className="text-xs flex items-center justify-between"
                style={{ letterSpacing: 2 }}
              >
                <p className="text-bold">Correo Electrónico </p>
                <p>usuario97@gmail.com</p>
              </div>
            </div>
          )}
          {activeSection === "direccion" && (
            <div>
              <div
                className="text-xs flex"
                style={{ letterSpacing: 2 }}
              >
                <p>Calle ejemplo1</p>
                <p className="underline ml-2 ">Modificar dirección</p>
              </div>
            </div>
          )}
          {activeSection === "pedidos" && (
            <div className="lg:ml-8">
              <p className="text-sm mb-8">Mis pedidos</p>
              <CardOrders
                image={exampleProduct1}
                name={"Gorro Simppa"}
                color={"Melange Rosa"}
                amount={"1"}
                price={"400.00"}
              />
            </div>
          )}
          {activeSection === "lista-deseos" && (
            <div className="lg:ml-8">
              <p className="text-sm mb-8">Mi lista de deseos</p>
              <CardOrders
                image={exampleProduct1}
                name={"Gorro Simppa"}
                color={"Melange Rosa"}
                amount={"1"}
                price={"400.00"}
              />
            </div>
          )}
          {activeSection === "cerrar-sesion" && (
            <div>¿Seguro que quieres cerrar sesión?</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Profile;
