import { myFetch, ErrorHandler, HttpMethod } from "./baseService";
import { apiRequest } from "./newBaseService";

export interface Product {
  id: number;
  name: string;
  description: string;
  barcode: string;
  stock: number;
  stockMinimum: number;
  salePrice: number;
  saleLocalPrice: number;
  saleInternationalPrice: number;
  purchasePrice: number;
  publish: boolean;
  order: number;
  updateAt: string;
  createdAt: string;
  deletedAt: null;
  type: any;
  colors: any[];
  sizes: any[];
  images: string[];
}
export interface Image {
  id: number;
  imagePath: string;
}

export interface SizeProduct {
  id: number;
  name: string;
  quantity: number;
}

export interface ColorProduct {
  id: number;
  name: string;
  imagePath: string;
  sizes: SizeProduct[];
  images: Image[];
}

export interface ProductGeneral {
  id: number;
  name: string;
  slug: string;
  description: string;
  materialComposition?: string;
  garmentCare?: string;
  price: number;
  quantity?: number;
  type: string;
  subType: string;
  collection: string;
  colors: ColorProduct[];
  images: string[];
  selectedColor: string;
  selectedSize: string;
  selectedImage: string;
}

export type GeneralResponse = {
  message: string;
  data: ProductGeneral[] | ProductGeneral;
};

export type GeneralDetailResponse = {
  message: string;
  data: ProductGeneral;
};

type GeneralResponseHandler = (data: GeneralResponse) => void;
type GeneralDetailResponseHandler = (data: GeneralDetailResponse) => void;
export const productsPublished = async (
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "shoppings/products/publish?page=1&limit=10&establishmentId=1",
    "",
    resultHandler,
    errorHandler,
    HttpMethod.GET
  );

export const searchProducts = async (
  search: string,
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "shoppings/products/" + search + "?establishmentId=1",
    "",
    resultHandler,
    errorHandler,
    HttpMethod.POST
  );

export const getDetailProduct = async (
  productName: string
): Promise<GeneralDetailResponse> => {
  try {
    const response = await apiRequest<GeneralDetailResponse>(
      `shopping/product/${productName}`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(`Error fetching collection products: ${error.message}`);
  }
};

export const getProductsBySection = async (
  sectionName: string,
  page: number = 1,
  limit: number = 10
): Promise<{
  message: string;
  data: ProductGeneral[];
}> => {
  try {
    const response = await apiRequest<{
      message: string;
      data: ProductGeneral[];
    }>(
      `shopping/productsBySection?sectionName=${sectionName}&page=${page}&limit=${limit}`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(`Error fetching collection products: ${error.message}`);
  }
};
