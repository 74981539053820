import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk"; // Importación correcta como named export
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// @ts-ignore
import { encryptTransform } from "redux-persist-transform-encrypt";
import rootReducer from "./store";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: "SECRET_TRANSFORM",
      onError: (_error: any) => { },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk)
);
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;