import { useEffect, useState } from "react";

import {
  Section,
  sectionsTypesPublished,
} from "../../services/sectionsService";

const FindUs = () => {
  const [, setSections] = useState<Section[]>([]);

  const [showInfoPeru, setShowInfoPeru] = useState<boolean>(true);
  const [showInfoNewZealand, setShowInfoNewZealand] = useState<boolean>(false);

  const toggleInfoPeru = () => {
    setShowInfoPeru(!showInfoPeru);
  };

  const toggleInfoNewZealand = () => {
    setShowInfoNewZealand(!showInfoNewZealand);
  };

  useEffect(() => {
    const initial = () => {
      sectionsTypesPublished(
        ({ data }) => setSections(data as Section[]),
        (err) => console.error(err)
      );
    };
    initial();
    return () => {
      initial();
    };
  }, []);

  return (
    <>
      <section className="google-map mb-5">
        <h2 className="d-none">Contact US</h2>
        {/* <div id="map" className="google-map__wrapper h-[745px]"></div> */}
        {/* <iframe className="google-map__wrapper" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d983.4667123320628!2d-71.5334767!3d-16.3933324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424a51c58565b1%3A0xb97c98d7e6dc2cd3!2sCampo%20Redondo%20109-111%2C%20Arequipa%2004001!5e0!3m2!1ses!2spe!4v1659826356020!5m2!1ses!2spe" width="600" height="450" style={{ border: 0 }} loading="lazy"></iframe> */}
        <div style={{ width: "100%", height: 745 }}>
          <iframe
            src={
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d983.4667123320628!2d-71.5334767!3d-16.3933324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424a51c58565b1%3A0xb97c98d7e6dc2cd3!2sCampo%20Redondo%20109-111%2C%20Arequipa%2004001!5e0!3m2!1ses!2spe!4v1659826356020!5m2!1ses!2spe"
            }
            width="100%"
            height="100%"
            style={{ border: 0 }}
            // allowFullScreen=""
            loading="lazy"
            title="Google Maps"
          ></iframe>
        </div>
      </section>
      <div className="text-primaryColor">
        <h2
          className="text-center text-primaryColor mt-16 text-3xl uppercase flex justify-center"
          style={{ letterSpacing: 2 }}
        >
          Encuentra una tienda
        </h2>

        <div className="x1280:px-36 ">
          <div className="mt-20 px-8">
            <div>
              <div
                className="flex items-center justify-between"
                onClick={toggleInfoPeru}
              >
                <h2 className="mb-2 uppercase text-primaryColor  text-xl">
                  Perú (01)
                </h2>
                <button className="text-2xl">{showInfoPeru ? "-" : "+"}</button>
              </div>
              {!showInfoPeru && (
                <div className="border-t border-primaryColor" />
              )}
              {showInfoPeru && (
                <div className="mt-4 grid md:grid-cols-3 grid-cols-1 items-center justify-center border-2 border-primaryColor p-4 text-sm">
                  <div className="flex items-center justify-center lg:justify-start">
                    <p>
                      Hotel 1 <span className="font-bold">Hotel Fundador</span>
                    </p>
                  </div>
                  <div className="flex flex-col mt-2 lg:mt-0 items-center justify-center">
                    <p>Campo redondo 109-111</p>
                    <p>San Lázaro, Arequipa - Peru</p>
                  </div>
                  <div className="flex mt-2 lg:mt-0 items-center justify-center lg:justify-end">
                    <p>(+51) 977528365</p>
                  </div>
                </div>
              )}
            </div>
            <div>
              {/* <div className="mb-2 mt-10 flex items-center justify-between">
                <h2 className="uppercase text-primaryColor text-xl">
                  Nueva Zelanda (01)
                </h2>
                <button className="text-2xl" onClick={toggleInfoNewZealand}>
                  {showInfoNewZealand ? "-" : "+"}
                </button>
              </div> */}
              {/* {!showInfoNewZealand && (
                <div className="border-t border-primaryColor" />
              )} */}

              {/* {showInfoNewZealand && (
                <div className="mt-4 grid md:grid-cols-3 grid-cols-1 items-center justify-center border-2 border-primaryColor p-4 text-sm">
                  <div className="flex items-center justify-center lg:justify-start">
                    <p>
                      Hotel 1 <span className="font-bold">Hotel Fundador</span>
                    </p>
                  </div>
                  <div className="flex flex-col mt-2 lg:mt-0 items-center justify-center">
                    <p>Campo redondo 109-111</p>
                    <p>San Lázaro, Arequipa - Peru</p>
                  </div>
                  <div className="flex mt-2 lg:mt-0 items-center justify-center lg:justify-end">
                    <p>(+51) 977528365</p>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindUs;
