import { Link, useNavigate } from "react-router-dom";
import FullScreenSection from "../../components/home/FullScreenSection";
import boxExample from "../../assets/images/blog_example.jpg";
import imageCollection from "../../assets/images/our_history_1.jpg";
import imageBlog from "../../assets/images/our_history_2.jpg";

const BlogScreenDetails = () => {
  const navigate = useNavigate();

  return (
    <>
      <FullScreenSection
        urlImage={boxExample}
        positionTitle="center"
        positionFit={46}
      />
      <div className="flex flex-wrap mt-16 text-3xl text-center justify-center tracking-[3px] sm:text-2xl md:text-3xl lg:text-3xl">
        <p className="font-normal">La fibra de Alpaca:</p>
        <p className="font-semibold ml-2 italic border-0 sm:border sm:border-primaryColor sm:ml-2">
          Un Camino Sostenible Hacia la Moda
        </p>
      </div>
      <div className="text-primaryColor mt-11 text-base x1280:mx-44">
        <div className="mt-2 text-xs font-light tracking-widest text-justify">
          La industria de la moda vive hoy un profundo momento de cambio. Si
          antes lo más importante era innovar en diseño y estilo, hoy el desafío
          es estructural y tiene que ver con la forma en la cual nuestro arte se
          relaciona con el entorno. 
          <br />
          <br />
          El auge del fast fashion y cómo se ha demostrado que contamina al
          planeta, ha convertido en una urgencia la búsqueda por un quehacer
          textil más consciente, el trabajo con materiales ecológicos y la
          confección de prendas duraderas. Es una discusión muy amplia.
          <br />
          <br />
          En Ilaini sabemos que el trabajo con la fibra de alpaca puede abrir un
          camino a una moda distinta, sostenible, perdurable y valiosa. De eso
          queremos hablarte en este artículo. ¿Quieres saber más? A continuación
          te contamos.
        </div>
        <div className="mt-4 text-3xl font-normal tracking-[3px]">
          ¿Dé qué se trata el slow fashion qué tiene que ver la fibra de alpaca
          en esta historia?
        </div>
        <div className="mt-2 text-xs font-light tracking-widest text-justify">
          Cubrir nuestro cuerpo es una necesidad humana, pero también es
          expresar lo que somos, a través de esa vestimenta. Para satisfacer ese
          deseo nació la moda como arte y luego como industria.
          <br />
          <br />
          A lo largo de la historia, la moda ha ido expandiendo sus
          potencialidades en ambos terrenos. Para bien, la moda ha hecho volar
          la imaginación de quienes la crean, para mal, se ha convertido en un
          sector económico altamente contaminante, sobre todo con el auge del
          fast fashion, que crea prendas prácticamente desechables, tanto por su
          calidad como por la rápida rotación de las tendencias.
          <br />
          <br />
          ¿Qué hacer? Se impone la necesidad de volver a los orígenes y abrevar
          de aquellas prácticas textiles ancestrales con las cuales la ropa
          solía durar, ser funcional y atesorarse por generaciones. Un ejemplo
          claro es el de la fibra de alpaca.
          <br />
          <br />
          Las prendas confeccionadas con alpaca suelen ser uno de los recuerdos
          clásicos que llevan a casa las personas que visitan Perú (el 80% de su
          producción mundial está aquí). La fibra se obtiene a través de un
          proceso artesanal en el cual se esquila a un mamífero camélido del
          mismo nombre, endémico de los países de la región andina. Esto hace
          que la fibra de alpaca sea una materia prima producida únicamente en
          nuestros países y por tanto, además de un producto comercial sea todo
          un modo de vida y patrimonio cultural.
          <br />
          <br />
          Se obtiene en toda una gama de colores, finura y calidad. Su variedad
          más fina es la fibra de baby alpaca, que proviene del primer esquilado
          en la vida del animal, cuando este cumple 3 años. Es perfecto para
          prendas de lujo como bufandas, suéteres, tejido de punto, entre otros.
        </div>
        <div className="mt-4 text-3xl font-normal tracking-[3px]">
          ¿Por qué la fibra de alpaca puede abrir un nuevo camino de moda
          sostenible?
        </div>
        <div className="mt-2 text-xs font-light tracking-widest text-justify">
          Ya dijimos que en Perú la fibra de alpaca es patrimonio. Pero vista
          desde una perspectiva más actual, esta materia prima también nos habla
          de todo un movimiento por el slow fashion en el cual participan marcas
          locales y grupos de creadores y artesanos, quienes buscan preservar
          sus sabiduría heredada de generación en generación.
          <br />
          <br />
          En Ilaini creemos que la fibra de alpaca no solo es el principal
          componente de nuestras colecciones, sino también el vehículo a través
          del cual fomentamos un modo de producción textil más ético. ¿Cómo
          abrimos camino a la moda sostenible? Así:
        </div>
      </div>
      <div className="bg-bgBoxFooter x1280:mx-44 mt-10 px-10 py-10 space-y-2 text-base">
        <ol
          className="px-6 list-decimal text-xs text-justify"
          style={{ letterSpacing: 2 }}
        >
          <li className="font-bold">
            Confección Centrada en la Durabilidad
            <p className="font-normal mt-4">
              Las prendas Ilaini, confeccionadas con fibras de alpaca, son lo
              contrario al fast fashion. Estamos hablando de vestimenta que
              puede acompañarte toda tu vida —e incluso durar más de una
              generación— porque es resistente a diversos cambios de ambiente,
              clima, lavados, uso prolongado y más. Por eso, también nos
              esforzamos en crear piezas atemporales que no responden a
              tendencias pasajeras.
              <br />
              <br />
              En ese sentido, el valor de lo que adquieres se incrementa dado
              que son piezas únicas, una pequeña obra de arte. Lo que tendrás
              para siempre en tu clóset será una prenda que nadie más tendrá en
              el suyo.
            </p>
          </li>
          <li className="mt-4 font-bold">
            Acompañamos la Producción Local
            <p className="font-normal mt-4">
              La ropa de alpaca de Ilaini es realizada gracias al trabajo de
              productores locales, hilanderas y artesanos del Perú que tienen en
              este proceso productivo su modo de vida, sustento, resiliencia y
              sabiduría ancestral. Con estos grupos, establecemos acuerdos de
              trabajo justos y transparentes.
              <br />
              <br />
              Es así como al adquirir nuestras prendas, no solo apoyas a la
              economía local y familiar de estas comunidades, sino que haces tu
              parte en el esfuerzo por mantener viva su tradición.
            </p>
          </li>
          <li className="mt-4 font-bold">
            Moda que NO Contamina
            <p className="font-normal mt-4">
              El proceso productivo de Ilaini tiene un nivel mínimo de emisiones
              al medio ambiente, ya que se realiza mediante un proceso que no
              hay forma de industrializar.
              <br />
              <br />
              Nuestros ciclos de creación, procesamiento y confección no
              involucran ningún proceso químico intensivo ni maquinaria pesada o
              que perjudiquen al entorno cercano a donde se realiza. Tampoco
              implica contaminación con micro plástico.
              <br />
              <br />Y así como no contaminamos en la etapa de producción, las
              prendas de alpaca son biodegradables. Se trata de un producto cien
              por ciento orgánico que regresa a la tierra y la alimenta una vez
              finaliza su vida útil como indumentaria.
            </p>
          </li>
          <li className="mt-4 font-bold">
            Moda Libre de Crueldad Animal
            <p className="font-normal mt-4">
              Para la producción de la fibra de alpaca y de baby alpaca que
              usamos en Ilaini no se maltrata, perjudica ni sacrifica a ningún
              animal. El esquilado es amigable con los rebaños, que además
              mantienen un ciclo de vida digno y en hábitats adecuados. 
              <br />
              <br />
              Las alpacas no son trasquiladas antes de los 3 años de edad,
              cuando ya son completamente adultas, y mantenemos estrictos
              controles sobre su salud y alimentación.
            </p>
          </li>
          <li className="mt-4 mb-9 font-bold">
            Consumo Ético = Cambio en la Industria
            <p className="font-normal mt-4">
              En Ilaini creemos que un cambio en el paradigma de consumo será lo
              que marcará el surgimiento de una industria de la moda más
              amigable con el planeta.
              <br />
              <br />
              Por eso, fomentamos la compra consciente y animamos a nuestro
              público a reflexionar sobre el origen y la calidad de lo que
              adquieren. Invertir en ropa ecológica y durable es ganar ganar.
            </p>
          </li>
        </ol>
      </div>

      <div className="text-primaryColor mt-11 text-base x1280:mx-44 px-4">
        <div
          className="mt-16 text-3xl tracking-[3px] sm:text-2xl md:text-3xl lg:text-3xl "
          style={{ letterSpacing: 3 }}
        >
          <p className="font-semibold">Moda consciente, ecológica y peruana:</p>
          <p className="font-normal">Nuestro compromiso en Ilaini</p>
          <div
            className="mt-6 text-xs font-light tracking-widest"
            style={{ letterSpacing: 2 }}
          >
            Cada prenda Ilaini tiene una historia tras de sí, pero también tiene
            una larga historia que comienza al llegar a las manos de quien la
            adquiere. 
            <br />
            <br />
            Trabajamos de la mano con las comunidades alpaqueras y con artesanos
            de Arequipa, Perú, creando productos de calidad internacional con
            máximos estándares éticos y estéticos, para que nuestras prendas te
            acompañen toda la vida.
          </div>
          <div
            className="flex flex-wrap mt-2 tracking-[3px] text-xs"
            style={{ letterSpacing: 2 }}
          >
            <p>¿Quieres conocer más?</p>
            <p className="font-semibold italic">
              Echa un vistazo a nuestro catálogo.
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-10 px-12">
        <div className="row">
          <div className="col-md-6 col-12 ">
            <div className="flex justify-center items-center relative">
              <img
                className="w-full lg:h-[480px] "
                src={imageCollection}
                alt=""
              />
              <button
                onClick={() => navigate("/fiber-catalog")}
                className="absolute flex items-center justify-center bottom-0 mb-16 bg-bgColor p-2 md:px-14 md:py-2 w-72 "
              >
                <div className="flex items-center justify-center uppercase tracking-widestcustom text-base text-xs">
                  Catálogo de textiles
                </div>
              </button>
            </div>
          </div>
          <div className="col-md-6 col-12 ">
            <div className="flex justify-center items-center relative">
              <img className="w-full lg:h-[480px] " src={imageBlog} alt="" />
              <div className="absolute flex items-center justify-center bottom-0 mb-16 bg-bgColor p-2 md:px-14 md:py-2 w-72 ">
                <div className="flex items-center justify-center uppercase tracking-widestcustom text-base text-xs">
                  <Link to="/fiber-catalog">Título Blog 2</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogScreenDetails;
