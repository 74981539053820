import React from "react";
import Banner from "../../components/common/Banner";
import bannerImage from "../../assets/images/our_history.jpg";
import imageCollection from "../../assets/images/our_history_1.jpg";
import imageBlog from "../../assets/images/our_history_2.jpg";
import { Link, useNavigate } from "react-router-dom";
import FullScreenSection from "../../components/home/FullScreenSection";

const OurHistory = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Banner banner={bannerImage} text={"NUESTRA HISTORIA"} /> */}
      <FullScreenSection
        urlImage={bannerImage}
        title="NUESTRA HISTORIA"
        positionTitle="center"
        positionFit={46}
      />
      <div className="text-primaryColor mt-5 md:px-0 px-6">
        <div
          className="flex justify-center text-primaryColor md:text-3xl text-xl"
          style={{ letterSpacing: 3 }}
        >
          <h2 className="font-monserrat ">ILAINI:</h2>
          <h2 className="font-monserratLightItalic tracking-wider ">
            QUIENES SOMOS
          </h2>
        </div>
        <div className="text-base lg:px-60 text-justify font-monserrat tracking-wider2 font-light text-xs">
          <p className="mt-8">
            En Ilaini, estamos comprometidos a compartir con el mundo la rica
            cultura peruana a través de nuestras prendas. Somos una apasionada
            empresa textilera dedicada al diseño, producción y comercialización
            de productos textiles elaborados con fibra de Baby Alpaca. Nuestra
            inspiración fusiona la modernidad industrial con la herencia textil
            ancestral del Perú, creando un mundo de texturas y colores únicos
            mediante técnicas tradicionales.
          </p>
          <p className="mt-4">
            Nuestra historia comienza con Andrea Alcázar Dueñas, quien trabajó
            por mucho tiempo en la industria textil y se enamoró de sus fibras.
            Este amor la llevó a planificar la creación de Ilaini. Andrea viajó
            a las profundidades del Perú para conectar con los ganaderos
            alpaqueros y reconocer las carencias que enfrentan a diario, a pesar
            de ser fundamentales en la cadena de suministro de la fibra de
            alpaca para grandes industrias textiles. Conmovida por esta
            realidad, Andrea fundó Ilaini con una visión clara: desarrollar
            proyectos sociales sostenibles que beneficien a los ganaderos
            alpaqueros, protejan el medio ambiente y promuevan la sostenibilidad
            en la industria de la moda. Aunque el camino es largo, el corazón y
            la fuerza que impulsan a Ilaini se basan en esta misión.
          </p>
          <p className="mt-4">
            En Ilaini, creemos en el comercio justo y en las prácticas de
            trabajo responsable. Nos enfocamos en el crecimiento de los
            artesanos que colaboran con nosotros, asegurando que cada pieza
            refleje el talento y la dedicación peruanos, con el objetivo
            principal de satisfacer a nuestros clientes. Nuestro propósito es
            adoptar procesos de producción textil amigables con el medio
            ambiente, seleccionando fibras de alpaca de auquénidos criados en
            instalaciones que priorizan la preservación de esta especie. Nos
            esforzamos por evitar la confección masiva, valorando el tiempo y la
            dedicación que cada pieza hecha a mano requiere para alcanzar su
            resultado final
          </p>
          <p className="mt-4">
            La fibra de Baby Alpaca, que proviene del primer esquilado en la
            vida de una alpaca, es muy fina y pura, con un diámetro de alrededor
            de 22.5 micrones. Esta fibra se utiliza para crear prendas finas de
            punto debido a su calidad excepcional y extrema suavidad, siendo uno
            de los materiales más valiosos y apreciados en el mercado. Nuestro
            objetivo es consolidarnos como líderes en el mercado nacional e
            internacional, destacándonos por la calidad excepcional de nuestros
            productos. Nos dedicamos a satisfacer las necesidades de nuestros
            clientes mediante productos únicos tejidos en fibra de Baby Alpaca,
            brindando experiencias sostenibles e innovadoras gracias a un equipo
            comprometido y empoderado.
          </p>
          <p className="mt-4">
            En Ilaini, no solo creamos prendas; creamos experiencias, historias
            y conexiones que reflejan la riqueza de nuestra cultura y el
            compromiso con la excelencia y la sostenibilidad. Únete a nosotros y
            descubre la magia de la Baby Alpaca peruana.
          </p>
        </div>
        <div className="grid md:grid-cols-2 mt-8 gap-2  x1280:px-20">
          <div className="flex justify-center items-center relative">
            <img
              className="block w-full lg:h-[480px] "
              src={imageCollection}
              alt=""
            />
            <button
              onClick={() => navigate("/fiber-catalog")}
              className="absolute flex items-center justify-center bottom-0 mb-16 bg-bgColor p-2 md:px-14 md:py-2 w-72 "
            >
              <div className="flex items-center justify-center uppercase tracking-widestcustom text-base text-xs">
                <Link to="/fiber-catalog">Catálogo de textiles</Link>
              </div>
            </button>
          </div>
          <div className="flex justify-center items-center relative">
            <img className="block w-full lg:h-[480px]" src={imageBlog} alt="" />
            <div className="absolute flex items-center justify-center bottom-0 mb-16 bg-bgColor py-2 px-8 md:px-14 md:py-2 w-72">
              <div className="flex items-center justify-center uppercase tracking-widestcustom text-base text-xs">
                <Link to="/blog-screen">Nuestro Blog</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurHistory;
