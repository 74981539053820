import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

interface CardBigType {
  image: string;
  title?: string;
}

const CardBig: React.FC<CardBigType> = ({ image, title }) => {
  return (
    <>
      <div className="flex justify-center">
        <div className="uppercase w-[341px] h-[341px] border-primaryColor border-2 flex flex-col justify-between p-3 text-15px">
          <div className="flex justify-center items-center h-full">
            <img
              src={image}
              alt="lanaMerino"
              className="w-[131px] h-[131px] object-cover"
            />
          </div>
          <div className="mt-auto bg-black py-2 text-center">
            <p className="uppercase text-white text-xs">{title}</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default CardBig;
