import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/config/redux-persist";

const CartDrawer = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => state.Cart.products);

  console.error(cart);
  return (
    <div
      className="aside aside_right overflow-hidden cart-drawer"
      // aside_visible"
      id="cartDrawer"
    >
      <div className="aside-header d-flex align-items-center">
        <h3 className="text-uppercase fs-6 mb-0">
          Carrito ( <span className="cart-amount js-cart-items-count">1</span> ){" "}
        </h3>
        <button className="btn-close-lg js-close-aside btn-close-aside ms-auto"></button>
      </div>

      <div className="aside-content cart-drawer-items-list">
        {cart.map((product) => (
          <>
            <div className="cart-drawer-item d-flex position-relative">
              <div className="position-relative">
                <img
                  loading="lazy"
                  className="cart-drawer-item__img"
                  src={product?.selectedImage}
                  alt=""
                />
              </div>
              <div className="cart-drawer-item__info flex-grow-1">
                <h6 className="cart-drawer-item__title fw-normal">
                  {product?.name}
                </h6>
                <p className="cart-drawer-item__option text-secondary">
                  Color: {product.selectedColor}
                </p>
                <p className="cart-drawer-item__option text-secondary">
                  Size: {product.selectedSize}
                </p>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <div className="qty-control position-relative">
                    <input
                      onChange={(e) => console.warn(e)}
                      type="number"
                      name="quantity"
                      value={product.quantity}
                      min="1"
                      className="qty-control__number border-0 text-center"
                    />
                    <div className="qty-control__reduce text-start">-</div>
                    <div className="qty-control__increase text-end">+</div>
                  </div>
                  <span className="cart-drawer-item__price money price">
                    S/.{product?.price.toFixed(2)}
                  </span>
                </div>
              </div>

              <button className="btn-close-xs position-absolute top-0 end-0 js-cart-item-remove"></button>
            </div>
            <hr className="cart-drawer-divider" />
          </>
        ))}
      </div>

      <div className="cart-drawer-actions position-absolute start-0 bottom-0 w-100">
        <hr className="cart-drawer-divider" />
        <div className="d-flex justify-content-between">
          <h6 className="fs-base fw-medium">SUBTOTAL:</h6>
          <span className="cart-subtotal fw-medium">S/.176.00</span>
        </div>
        <a href="./shop_cart.html" className="btn btn-light mt-3 d-block">
          Ver Carrito
        </a>
        <a href="./shop_checkout.html" className="btn btn-primary mt-3 d-block">
          Verificar
        </a>
      </div>
    </div>
  );
};
export default CartDrawer;
