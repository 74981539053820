import React, { useState, useEffect } from "react";
import imageClothingCare from "../../assets/images/clothing_care.jpg";
import imageClothingCareCut from "../../assets/images/BANNER.jpg";
import { Link } from "react-router-dom";
import FullScreenSection from "../../components/home/FullScreenSection";
import lavado from "../../assets/images/lavado.jpg";
import planchado from "../../assets/images/planchado.jpg";
import limpieza from "../../assets/images/limpieza.jpg";
import CardBig from "../../components/home/CardBig";

const ClothingCare = () => {
  const [showWashed, setShowWashed] = useState<boolean>(false);
  const [showIroning, setShowIroning] = useState<boolean>(false);
  const [showDryCleaning, setShowDryCleaning] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState(imageClothingCare);

  const toggleWashed = () => {
    setShowWashed(!showWashed);
  };

  const toggleIroning = () => {
    setShowIroning(!showIroning);
  };

  const toggleDryCleaning = () => {
    setShowDryCleaning(!showDryCleaning);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) {
        setCurrentImage(imageClothingCareCut);
      } else {
        setCurrentImage(imageClothingCare);
      }
    };

    window.addEventListener("resize", handleResize);

    // Check initial size
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <FullScreenSection
        urlImage={currentImage}
        title="CUIDADO DE PRENDAS"
        positionTitle="center"
        positionFit={46}
      />

      <main className="lg:px-20 container text-primaryColor py-16">
        <h2
          className="flex justify-center text-primaryColor text-3xl  text-center md:text-4xl font-montserrat uppercase"
          style={{ letterSpacing: 3 }}
        >
          Cuidado de prenda
        </h2>
        <p
          className="x1280:px-32 mt-10 text-base text-xs font-montserrat text-justify"
          style={{ letterSpacing: 2 }}
        >
          Bienvenido a nuestra guía de lavado, diseñada para brindarte consejos
          prácticos y útiles sobre cómo cuidar tus prendas de forma adecuada.
          Sigue estos consejos para garantizar que tus prendas se mantengan
          frescas, limpias y como nuevas durante más tiempo.
        </p>
        <div className="grid justify-center md:grid-cols-2 x1280:grid-cols-3  gap-4 text-center mt-10">
          <CardBig image={lavado} title="Lavado" />
          <CardBig image={planchado} title="planchado" />
          <CardBig image={limpieza} title="Limpieza" />
        </div>
        <div className="mt-20 x1280:px-32 text-primaryColor">
          <div
            className="flex space-y-2 items-center justify-between"
            onClick={toggleWashed}
          >
            <h2 className="uppercase text-2xl">Lavado</h2>
            <button className="text-2xl">{showWashed ? "-" : "+"}</button>
          </div>
          <div className="border-t-2 border-black opacity-50 py-3 " />
          {showWashed && (
            <>
              <ol className="list-decimal px-6">
                <li className="text-xs font-bold " style={{ letterSpacing: 2 }}>
                  Lee la etiqueta de cuidado:{" "}
                  <p className="font-normal text-justify">
                    es fundamental revisar la etiqueta de cuidado de la prenda
                    para asegurarse de seguir correctamente las instrucciones de
                    lavado ya que estas pueden variar según la composición de la
                    prenda o el tipo de tejido.
                  </p>
                </li>
                <li className="text-xs font-bold " style={{ letterSpacing: 2 }}>
                  Lavar a mano en agua fría,{" "}
                  <p className="font-normal text-justify">
                    evitando frotar con fuerza excesiva o retorcer la prenda, ya
                    que esto podría dañar las fibras delicadas y es esencial
                    para preservar su forma y durabilidad.
                  </p>
                </li>
                <li className="text-xs font-bold " style={{ letterSpacing: 2 }}>
                  Eliminar el exceso de agua:{" "}
                  <p className="font-normal text-justify">
                    Para eliminar el exceso de agua, evite retorcer la prenda.
                    En su lugar, presiona suavemente para sacar el agua. También
                    puedes envolver la prenda en una toalla limpia y enrollarla.
                    Luego, presiona suavemente para que la toalla absorba el
                    exceso de agua.
                  </p>
                </li>
                <li className="text-xs font-bold " style={{ letterSpacing: 2 }}>
                  Secado:{" "}
                  <p className="font-normal text-justify">
                    Coloca la prenda en una superficie plana para que se seque
                    naturalmente, evitando la luz directa del sol, ya que esto
                    podría dañar las fibras.
                  </p>
                </li>
              </ol>
            </>
          )}
        </div>
        <div className="mt-4 x1280:px-32 text-primaryColor">
          <div
            className="flex space-y-2 items-center justify-between"
            onClick={toggleIroning}
          >
            <h2 className="uppercase text-2xl" style={{ letterSpacing: 2 }}>
              Planchado
            </h2>
            <button className="text-2xl">{showIroning ? "-" : "+"}</button>
          </div>

          <div className="border-t-2 border-black opacity-50 py-3 " />
          {showIroning && (
            <p className="text-xs px-6" style={{ letterSpacing: 2 }}>
              Si la prenda presenta arrugas o pliegues, es posible plancharla.
              Se recomienda el uso de una plancha vertical a vapor
            </p>
          )}
        </div>

        <div className="mt-4 x1280:px-32 text-primaryColor">
          <div
            className="space-y-2 flex items-center justify-between"
            onClick={toggleDryCleaning}
          >
            <h2 className="uppercase text-2xl " style={{ letterSpacing: 2 }}>
              Limpieza en seco
            </h2>
            <button className="text-2xl">{showDryCleaning ? "-" : "+"}</button>
          </div>

          <div className="border-t-2 border-black opacity-50 py-3 " />
          {showDryCleaning && (
            <>
              <div className="px-6">
                <p
                  className="text-xs text-justify"
                  style={{ letterSpacing: 2 }}
                >
                  Confía ciertas tareas a los expertos. La limpieza en seco es
                  la mejor opción para ciertas prendas de fibra, ya que preserva
                  el tejido, elimina manchas difíciles, evita el encogimiento y
                  la decoloración, y mantiene el acabado y la forma.
                </p>
                <p
                  className="text-xs text-justify"
                  style={{ letterSpacing: 2 }}
                >
                  La experiencia y los métodos especializados de los
                  profesionales aseguran un cuidado óptimo para estas prendas.
                </p>
              </div>
            </>
          )}
        </div>

        <div className="bg-bgBoxFooter x1280:mx-32 mt-10 px-10 py-10 space-y-2 text-base">
          <h2
            className="text-2xl text-primaryColor uppercase mb-11"
            style={{ letterSpacing: 2 }}
          >
            Algunos Tips
          </h2>
          <ol
            className="px-6 list-decimal text-xs text-justify"
            style={{ letterSpacing: 2 }}
          >
            <li>
              No utilizar lavadora o secadora para el lavado o secado de prendas
              tejidas.
            </li>
            <li className="mt-4">Nunca utilices blanqueador ni lejía.</li>
            <li className="mt-4">
              Para evitar que la prenda se achique o se apelmace, se debe evitar
              el contacto con agua caliente y abstenerse de escurrirla o secarla
              estrujándola.
            </li>
            <li className="mt-4">
              Para conservar la forma y suavidad de tu prenda de alpaca, evita
              colgarla cuando esté mojada. En su lugar, déjala secar al aire
              sobre una superficie plana. Colgarla mientras está húmeda puede
              estirar las fibras y deformar la prenda.
            </li>
            <li className="mt-4 mb-9">
              Es recomendable no lavar las prendas de alpaca con demasiada
              frecuencia. La alpaca es una fibra natural que tiende a mantenerse
              limpia y fresca durante más tiempo que otros materiales. Además,
              el lavado frecuente puede provocar un desgaste prematuro de las
              fibras y reducir la suavidad y la calidad de la prenda con el
              tiempo.
            </li>
          </ol>
        </div>
      </main>
    </>
  );
};

export default ClothingCare;
