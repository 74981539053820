import { useEffect, useState } from "react";
import CartDrawer from "../components/home/CartDrawer";
import SiteMap from "../components/home/SiteMap";
import BannerHome from "../components/common/BannerHome";
import kidsBanner from "../assets/images/kids-banner.jpg";
import CardProduct from "../components/home/CardProduct";
import imageCollection from "../assets/images/our_history_1.jpg";
import { useNavigate } from "react-router-dom";
import {
  CollectionProductHome,
  getCollectionProductHome,
} from "../services/homeService";
import FullScreenLoaderNew from "../components/utils/FullScreenLoaderNew";
import CardProductNew from "../components/home/CardProductNew";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../store/loadingSlice";

const HomeAlternative = () => {
  const navigate = useNavigate();
  const [collectionsProductsHome, setCollectionsProductsHome] = useState<
    CollectionProductHome[]
  >([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    initial();
  }, []);
  const initial = async () => {
    dispatch(startLoading());
    try {
      const { data } = await getCollectionProductHome();
      setCollectionsProductsHome(data);
    } catch (error) {
      console.error("Error fetching collections", error);
    } finally {
      dispatch(stopLoading());
    }
  };
  return (
    <>
      <main>
        <BannerHome
          banner={collectionsProductsHome?.[0]?.collection?.imagePath || ""}
          title={collectionsProductsHome?.[0]?.collection?.name || ""}
          textButton={
            collectionsProductsHome?.[0]?.collection?.nameButton || ""
          }
        />
        <section
          id="carousel-products"
          className="products-carousel container px-11"
        >
          <h2
            className="section-title text-uppercase text-left mb-4 mt-5 pb-xl-2 mb-xl-4"
            style={{
              fontFamily: "Montserrat",
              color: "#171717",
              letterSpacing: 2,
              fontWeight: 400,
              fontSize: 15,
            }}
          >
            EQUILIBRIO NATURAL
          </h2>
          <div className="my-4 flex flex-wrap justify-center gap-[20px]">
            <CardProductNew
              product={collectionsProductsHome?.[0]?.products[0]}
              showPrice={false}
              buttonAdd="/product/1"
              buttonMore="/product/1"
            />
            <CardProductNew
              product={collectionsProductsHome?.[0]?.products[1]}
              showPrice={false}
              buttonAdd="/product/1"
              buttonMore="/product/1"
            />
            <CardProductNew
              product={collectionsProductsHome?.[0]?.products[2]}
              showPrice={false}
              buttonAdd="/product/1"
              buttonMore="/product/1"
            />
          </div>
        </section>
        <BannerHome
          banner={collectionsProductsHome?.[1]?.collection?.imagePath || ""}
          title={collectionsProductsHome?.[1]?.collection?.name || ""}
          textButton={
            collectionsProductsHome?.[1]?.collection?.nameButton || ""
          }
          buttonLink="/new-season"
        />
        <div className="mt-10 flex flex-wrap justify-center gap-8">
          <CardProduct
            product={collectionsProductsHome?.[1]?.products[0]}
            showPrice={false}
            showName={false}
            buttonAdd="/product/1"
            buttonMore="/product/1"
          />
          <CardProduct
            product={collectionsProductsHome?.[1]?.products[1]}
            showPrice={false}
            showName={false}
            buttonAdd="/product/1"
            buttonMore="/product/1"
          />
          <CardProduct
            product={collectionsProductsHome?.[1]?.products[2]}
            showPrice={false}
            showName={false}
            buttonAdd="/product/1"
            buttonMore="/product/1"
          />
          <CardProduct
            product={collectionsProductsHome?.[1]?.products[3]}
            showPrice={false}
            showName={false}
            buttonAdd="/product/1"
            buttonMore="/product/1"
          />
        </div>

        <BannerHome
          banner={kidsBanner}
          title="Ilaini kids"
          textButton="Niños"
          buttonLink="/category-kids"
        />
        <div className="container mt-10">
          <div className="row">
            <div className="col-md-6 col-12 ">
              <div className="flex justify-center items-center relative">
                <img
                  className="w-full lg:h-[480px] "
                  src={imageCollection}
                  alt=""
                />
                <button
                  onClick={() => navigate("fiber-catalog")}
                  className="absolute flex items-center justify-center bottom-0 mb-16 bg-bgColor p-2 md:px-14 md:py-2 w-72 "
                >
                  <div className="flex items-center justify-center uppercase tracking-widestcustom text-base text-xs">
                    Catálogo de textiles
                  </div>
                </button>
              </div>
            </div>
            <button
              onClick={() => navigate("sale")}
              className="col-md-6 col-12 position-relative object-cover"
            >
              <img
                src={require("./../assets/images/sale.jpg")}
                alt="Sale"
                className="img-fluid w-full lg:h-[480px]"
              />
              <div className="sale-text">
                <h1
                  className="text-white"
                  style={{ fontFamily: "EUR42", fontWeight: "400" }}
                >
                  SALE
                </h1>
                <p style={{ fontFamily: "Montserrat", fontSize: 24 }}>
                  Prendas hasta con el <b> 50% </b>de descuento
                </p>
              </div>
            </button>
          </div>
        </div>
        <CartDrawer />
        <SiteMap />
        <div id="scrollTop" className="visually-hidden end-0"></div>
        <div className="page-overlay"></div>
      </main>
    </>
  );
};

export default HomeAlternative;
