import { useEffect } from "react";

export const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const AddingScript = (url: string): Promise<string> => {
  return new Promise((res, rej) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;

    // Agregamos eventos para detectar la carga correcta o errores
    script.onload = () => {
      console.log("Script cargado correctamente");
      res("Script cargado con éxito"); // Resolviendo con un valor
    };

    script.onerror = () => {
      rej(new Error("Error al cargar el script")); // Rechazando en caso de error
    };

    document.body.appendChild(script);
  });
};
