import React, { useState } from "react";
import { Link } from "react-router-dom";
import CardSmall from "../../components/home/CardSmall";
import entrega from "../../assets/images/entrega.jpg";
import recojo from "../../assets/images/recojo.jpg";
import rastrea from "../../assets/images/rastrea.jpg";

const Shipping = () => {
  const [showShipping, setShowShipping] = useState<boolean>(false);
  const [showPick, setShowPick] = useState<boolean>(false);
  const [showTracking, setShowTracking] = useState<boolean>(false);

  const toggleShipping = () => {
    setShowShipping(!showShipping);
  };

  const togglePick = () => {
    setShowPick(!showPick);
  };

  const toggleTacking = () => {
    setShowTracking(!showTracking);
  };

  return (
    <>
      <div className="text-primaryColor" style={{ letterSpacing: 3 }}>
        <h2 className="uppercase flex justify-center text-3xl mt-12 text-primaryColor">
          Envíos
        </h2>
        <div className="container mx-auto px-52 mt-20">
          <div className="grid gap-5 md:grid-cols-2 xl:grid-cols-3 justify-center">
            <CardSmall
              image={entrega}
              title="Conoce más"
              titleUp="Entrega Nacional"
            />
            <div className="justify-center">
              <CardSmall
                image={recojo}
                title="Conoce más"
                titleUp="Recojo en tienda"
              />
            </div>

            <div className="justify-center">
              <CardSmall
                image={rastrea}
                title="Conoce más"
                titleUp="Rastrea tu pedido"
              />
            </div>
          </div>
        </div>
        <div className="pt-12 space-y-2 x1280:px-44">
          <div className="flex items-center justify-between">
            <h2 className="uppercase text-2xl " style={{ letterSpacing: 2 }}>
              Entrega Nacional
            </h2>
            <button className="text-2xl" onClick={toggleShipping}>
              {showShipping ? "-" : "+"}
            </button>
          </div>
          <div className="border-t border-primaryColor" />
          {showShipping && (
            <>
              <div className="flex grid justify-center py-3 px-4">
                <p
                  className="text-xs text-justify"
                  style={{ letterSpacing: 2 }}
                >
                  Para asegurar que tu pedido realizado en la tienda online de
                  ILAINI (ilaini.com.pe) llegue correctamente y a tiempo, es
                  esencial que proporciones información precisa al momento de
                  completar tu compra. Si los datos ingresados son incorrectos o
                  incompletos, cualquier inconveniente que surja, incluyendo la
                  posible cancelación del pedido, será responsabilidad del
                  cliente.
                  <br />
                  <br />
                  Envío a Domicilio: Los productos se entregarán directamente en
                  la dirección proporcionada por el cliente.
                </p>
                <div
                  className="text-xs text-justify mt-2"
                  style={{ letterSpacing: 2 }}
                >
                  <li className="font-bold"> Proveedores de Envío:</li>
                  <p className="ml-4">
                    Utilizamos los servicios de Olva Courier para todas nuestras
                    entregas dentro del territorio nacional.
                  </p>
                  <li className="font-bold">Procesamiento del Pedido:</li>
                  <p className="ml-4">
                    Los pedidos se procesan y despachan dentro de 1 a 2 días
                    hábiles después de la confirmación del pago.
                  </p>
                  <li className="font-bold">Tiempo de Entrega:</li>
                  <ul className="ml-4">
                    <li>
                      El tiempo de entrega estándar es de 2 a 7 días hábiles,
                      dependiendo de la ubicación del cliente dentro del
                      territorio nacional.
                    </li>
                    <li>
                      En casos excepcionales, el tiempo de entrega podría
                      extenderse debido a circunstancias fuera de nuestro
                      control, como condiciones climáticas adversas o
                      interrupciones en el servicio de mensajería.
                    </li>
                  </ul>
                  <li className="font-bold">Costos de Envío:</li>
                  <ul className="ml-4">
                    <li>
                      Envíos a todos los departamentos y provincias del Perú:
                      S/25.00
                    </li>
                    <li>Envíos dentro de la ciudad de Arequipa: S/10.00</li>
                  </ul>
                  <li className="font-bold">Envío Gratuito:</li>
                  <p className="ml-4">
                    Ofrecemos envío gratuito en pedidos superiores a S/250.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="pt-12 space-y-2 x1280:px-44">
          <div className="flex items-center justify-between">
            <h2 className="uppercase text-2xl " style={{ letterSpacing: 2 }}>
              Recojo en tienda
            </h2>
            <button className="text-2xl" onClick={togglePick}>
              {showPick ? "-" : "+"}
            </button>
          </div>
          <div className="border-t border-primaryColor" />
          {showPick && (
            <div className="flex grid justify-center py-3 px-4">
              <p className="text-xs text-justify" style={{ letterSpacing: 2 }}>
                Los clientes pueden optar por recoger sus pedidos en nuestras
                tiendas físicas o en almacenes designados. Esta opción es
                gratuita y los pedidos estarán listos para retiro dentro de 1 a
                2 días hábiles después de la confirmación del pago. Los clientes
                recibirán una notificación por correo electrónico cuando su
                pedido esté listo para ser recogido.
              </p>
            </div>
          )}
        </div>
        <div className="pt-12 space-y-2 x1280:px-44">
          <div className="flex items-center justify-between">
            <h2 className="uppercase text-2xl " style={{ letterSpacing: 2 }}>
              Rastrea tu pedido
            </h2>
            <button className="text-2xl" onClick={toggleTacking}>
              {showTracking ? "-" : "+"}
            </button>
          </div>
          <div className="border-t border-primaryColor" />
          {showTracking && (
            <div
              className="flex grid justify-center py-3 px-4"
              style={{ letterSpacing: 2 }}
            >
              <p className="text-xs text-justify">
                Todos los pedidos realizados a través de ILAINI incluyen un
                número de seguimiento. Una vez que el pedido ha sido despachado,
                este número se envía al cliente por correo electrónico,
                permitiéndole monitorear el estado de su envío a través del
                sitio web de Olva Courier.
                <br />
                <br />
                ILAINI también realiza un seguimiento continuo de todos los
                pedidos despachados para asegurarse de que lleguen a su destino
                dentro del tiempo acordado. Sin embargo, la entrega podría no
                concretarse debido a las siguientes circunstancias:
              </p>
              <div
                className="text-xs text-justify"
                style={{ letterSpacing: 2 }}
              >
                <li className="mt-2">
                  La dirección de entrega proporcionada es incorrecta o está
                  incompleta, lo que impide localizarla.
                </li>
                <li>
                  No se encuentra nadie en el domicilio para recibir el pedido
                  en el momento de la entrega.
                </li>
                <p
                  className="text-xs text-justify"
                  style={{ letterSpacing: 2 }}
                >
                  En caso de que ocurra alguno de estos inconvenientes, ILAINI
                  se pondrá en contacto con el cliente para coordinar una nueva
                  fecha de entrega. Cualquier costo adicional que surja por la
                  reprogramación de la entrega será asumido por el cliente.
                </p>
                <div>
                  <p className="font-bold mt-3">Notificaciones</p>
                  <p>
                    Los clientes recibirán notificaciones por correo electrónico
                    en cada etapa del proceso de envío, incluyendo la
                    confirmación del pedido, el despacho y la entrega.
                  </p>
                </div>
                <div>
                  <p className="font-bold mt-3">Atención al Cliente:</p>
                  <p>
                    Nuestro equipo de atención al cliente está disponible para
                    asistir a los clientes con cualquier consulta o problema
                    relacionado con el envío.
                  </p>
                </div>
                <div>
                  <p className="mt-3">
                    Los clientes pueden contactarnos a través de
                  </p>
                  <li className="font-bold">
                    Correo Electrónico: atencioncliente@ilaini.com
                  </li>
                  <li className="font-bold">Llamando al: +51 977528365</li>
                </div>
                <p className="mt-3">Para recibir asistencia inmediata.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Shipping;
