import React from "react";
import Banner from "../../components/common/Banner";
import bannerImage from "../../assets/images/sustainability.jpg";
import imageCollection from "../../assets/images/our_history_1.jpg";
import imageBlog from "../../assets/images/our_history_2.jpg";
import FullScreenSection from "../../components/home/FullScreenSection";

const Sustainability = () => {
  return (
    <>
      <FullScreenSection
        urlImage={bannerImage}
        title="SOSTENIBILIDAD"
        positionTitle="center"
        positionFit={46}
      />
      <div className="text-primaryColor mt-16 text-base lg:px-44 px-6">
        <div className="text-center text-3xl font-normal tracking-[3px]">
          SOSTENIBILIDAD
        </div>
        <div className="mt-10 text-3xl font-normal tracking-[3px]">
          Ilaini: Innovación y Sostenibilidad en Cada Hilo{" "}
        </div>
        <div className="mt-2 text-xs font-light tracking-widest text-justify">
          En Ilaini, nos enorgullecemos de ofrecer prendas de vestir únicas,
          100% hechas a mano por artesanas de comunidades andinas. Nuestros
          productos, ligeros y de alta calidad, destacan por sus modelos
          innovadores.
        </div>
        <div className="mt-4 text-3xl font-normal tracking-[3px]">
          La Esencia de la Baby Alpaca
        </div>
        <div className="mt-2 text-xs font-light tracking-widest text-justify">
          La Baby Alpaca es la fibra obtenida del primer esquilado de una alpaca
          adulta, realizada cuando el animal ha alcanzado los tres años de edad.
          Este pelo, que mide 22.5 micrones, se utiliza para crear prendas finas
          de punto, caracterizadas por su suavidad y calidad excepcionales.
          Considerada una de las fibras más valiosas y apreciadas. Siendo, la
          Baby Alpaca es el corazón de nuestras creaciones.
        </div>
        <div className="mt-4 text-3xl font-normal tracking-[3px]">
          Compromiso Calidad - Innovación
        </div>
        <div className="mt-2 text-xs font-light tracking-widest text-justify">
          Nos encontramos incorporando nuevas texturas mientras mantenemos la
          calidad y elegancia que nos define. Nuestras prendas de diseño
          atemporal, prevalecen el tiempo ofreciendo una relación calidad-precio
          inigualable. Además, nuestro modelo de venta B2C y nuestra red de
          distribución internacional garantizan un proceso de compra y entrega
          ágil y eficiente.
        </div>
        <div className="mt-4 text-3xl font-normal tracking-[3px]">
          Fomentando el Comercio Justo y la Sostenibilidad
        </div>
        <div className="mt-2 text-xs font-light tracking-widest text-justify">
          Más allá de vender productos, en Ilaini buscamos compartir la
          experiencia de nuestras texturas y nuestra historia. Promovemos
          programas para familiarizar al público con nuestra cultura y fomentar
          el comercio justo, la sostenibilidad y el equilibrio con el medio
          ambiente. Nos esforzamos por mejorar la calidad de vida de las
          comunidades andinas, ofreciendo oportunidades y contando la historia
          detrás de cada prenda de alpaca.
        </div>

        <div className="mt-4 text-3xl font-normal tracking-[3px]">
          Más que Moda
        </div>
        <div className="mt-2 mb-40 text-xs font-light tracking-widest text-justify">
          Ilaini no es solo una marca; es un símbolo de empoderamiento,
          elegancia, minimalismo y justicia. Representa a una mujer determinada
          a crecer y a impulsar el crecimiento de su equipo y de las personas
          que la rodean. Nacida del amor por la fibra de alpaca y consciente de
          los desafíos sociales que enfrentan las comunidades alpaqueras, Ilaini
          busca no solo su propio crecimiento, sino también el de estas
          comunidades. Al ofrecer prendas de alta durabilidad, contribuimos al
          cuidado del medio ambiente, creando conciencia social y destacando los
          beneficios y el potencial de la alpaca.
          <br />
          <br />
          En Ilaini, cada prenda es una declaración de sostenibilidad, una
          conexión con la cultura andina y una promesa de calidad que perdura en
          el tiempo.
        </div>
      </div>
    </>
  );
};

export default Sustainability;
