import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { ProductGeneral } from "../../services/productsService";

interface CardProductType {
  product: ProductGeneral;
  showPrice?: boolean;
  showName?: boolean;
  priceOut?: string;
  category?: string;
  color?: string;
  size?: string;
  buttonAdd?: string;
  buttonMore?: string;
}

const CardProduct: React.FC<CardProductType> = ({
  product,
  showPrice = true,
  showName = true,
  priceOut,
  buttonAdd,
  buttonMore,
  category,
  color,
  size,
}) => {
  return (
    <div className="w-[282px] flex-shrink-0">
      <div className="pc__img-wrapper relative">
        <a href={"/product/" + product?.slug}>
          <img
            loading="lazy"
            src={product?.images[0]}
            width="330"
            height="400"
            alt={product?.name}
            className="pc__img"
          />
          <img
            loading="lazy"
            src={product?.images[1]}
            width="330"
            height="400"
            alt={product?.name}
            className="pc__img pc__img-second"
          />
        </a>
        <button
          className="pc__btn-wl absolute border-0 text-primary js-add-wishlist"
          title="Add To Wishlist"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <use href="#icon_heart"></use>
          </svg>
        </button>
      </div>
      <div className="pc__info relative" style={{ letterSpacing: 3 }}>
        {showName && (
          <h6 className="pc__title text-[10px]">
            <a href={"/product/" + product?.slug}>{product?.name}</a>
          </h6>
        )}
        <div className="product-card__price flex">
          {priceOut && (
            <span className="money price price-old text-[10px]">
              {priceOut}
            </span>
          )}
          {showPrice && (
            <span className="money price text-[10px]">
              S/.{product?.price.toFixed(2)}
            </span>
          )}
        </div>

        {buttonAdd && (
          <div>
            <a
              href={buttonAdd}
              className=" py-2 w-full inline-block justify-items-center uppercase text-xs bg-black text-white text-center
             "
              style={{ letterSpacing: 2 }}
            >
              Agregar al carrito
            </a>
          </div>
        )}

        {buttonMore && (
          <div>
            <a
              href={buttonMore}
              className="py-2 w-full inline-block justify-items-center uppercase text-xs block w-full h-8 border border-black text-black text-center bg-white"
            >
              Ver más
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardProduct;
