
export interface FetchOptions {
  method: string;
  headers?: { [key: string]: string };
  body?: string;
}

export type ErrorHandler = (error: string) => void;
export type FetchResponse = {
  message: string;
  data: any;
  statusCode?: number;
};
export const apiRequest = async <T,>(
  endpoint: string,
  options: FetchOptions = { method: "GET" }
): Promise<T> => {
  const url = process.env.REACT_APP_API_URL + endpoint;

  try {
    const response = await fetch(url, {
      method: options.method,
      headers: {
        "Content-Type": "application/json",
        ...(options.headers || {}),
      },
      body: options.body,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data: T = await response.json();
    return data;
  } catch (error: any) {
    throw new Error(`Fetch error: ${error.message}`);
  }
};
