import { useEffect, useState } from "react";
import FullScreenSection from "../../components/home/FullScreenSection";
import bannerImage from "../../assets/images/fiber.jpg";
import lanaMerino from "../../assets/images/lana_merino.jpg";
import { Link } from "react-router-dom";
import CardSmall from "../../components/home/CardSmall";

const FiberCatalog = () => {
  const [showAlpaca, setShowAlpaca] = useState<boolean>(false);
  const [showBabyAlpaca, setShowBabyAlpaca] = useState<boolean>(false);
  const [showLanaMerino, setShowLanaMerino] = useState<boolean>(false);
  const [showAlgodonOrganico, setShowAlgodonOrganico] =
    useState<boolean>(false);

  const toggleAlpaca = () => {
    setShowAlpaca(!showAlpaca);
  };

  const toggleBabyAlpaca = () => {
    setShowBabyAlpaca(!showBabyAlpaca);
  };

  const toggleLanaMerino = () => {
    setShowLanaMerino(!showLanaMerino);
  };

  const toggleAlgodonOrganico = () => {
    setShowAlgodonOrganico(!showAlgodonOrganico);
  };

  return (
    <>
      <FullScreenSection
        urlImage={bannerImage}
        title="CATALOGO DE FIBRAS"
        positionTitle="center"
        positionFit={46}
      />
      <div className="mt-16 text-center text-3xl font-normal tracking-[3px]">
        CATÁLOGO DE FIBRAS
      </div>
      <div className="container mx-auto px-16 mt-14 mb-32">
        <div className="grid gap-5 md:grid-cols-2 xl:grid-cols-4 justify-center">
          <CardSmall image={lanaMerino} title="Alpaca" />
          <CardSmall image={lanaMerino} title="Baby Alpaca" />
          <CardSmall image={lanaMerino} title="Lana Merino" />
          <CardSmall image={lanaMerino} title="Algodón organico" />
        </div>
        <div className="pt-12 space-y-2 x1280:px-44">
          <div
            className="flex items-center justify-between"
            onClick={toggleAlpaca}
          >
            <h2 className="uppercase text-2xl " style={{ letterSpacing: 2 }}>
              Alpaca
            </h2>
            <button className="text-2xl">{showAlpaca ? "-" : "+"}</button>
          </div>
          <div className="border-t border-primaryColor" />
          {showAlpaca && (
            <div className="flex grid justify-center py-3 px-4">
              <p className="text-xs text-justify" style={{ letterSpacing: 2 }}>
                La fibra de alpaca, originaria de Perú, es una verdadera joya en
                el mundo textil, apreciada por su suavidad incomparable,
                ligereza y calidez. Las prendas de alpaca ofrecen una
                experiencia de uso incomparable, combinando confort y elegancia
                en cada detalle. Además de su calidad excepcional, la fibra de
                alpaca destaca por su origen sostenible y ético. Las alpacas son
                animales resistentes que se adaptan a los entornos montañosos de
                Perú, y su fibra se recolecta de manera cuidadosa y respetuosa,
                sin causar daño a los animales. Este enfoque refleja el
                compromiso de Ilaini con la preservación del medio ambiente y el
                bienestar animal, lo que convierte a la industria de la alpaca
                en un ejemplo de prácticas responsables y éticas en la
                producción textil. 
              </p>
              <p
                className="text-xs mt-6 font-bold"
                style={{ letterSpacing: 2 }}
              >
                Micrones: 16
              </p>
            </div>
          )}
          <div
            className="mt-4 flex items-center justify-between"
            onClick={toggleBabyAlpaca}
          >
            <h2 className="uppercase text-2xl" style={{ letterSpacing: 2 }}>
              Baby Alpaca
            </h2>
            <button className="text-2xl">{showBabyAlpaca ? "-" : "+"}</button>
          </div>
          <div className="border-t border-primaryColor" />
          {showBabyAlpaca && (
            <div className="flex grid justify-center py-3 px-4">
              <p className="text-xs text-justify" style={{ letterSpacing: 2 }}>
                La baby alpaca, obtenida de la primera esquila del animal, es
                reconocida por producir fibras especialmente finas y suaves, lo
                que la convierte en una opción premium altamente valorada en el
                mundo de la moda y el tejido. Además de su exquisita suavidad,
                esta fibra presenta una variedad cromática excepcionalmente
                amplia, que abarca desde tonos suaves y delicados hasta colores
                más profundos y vibrantes, proporcionando una paleta de colores
                rica y versátil para crear prendas de alta calidad que se
                destacan por su lujo y confort. La baby alpaca no solo ofrece
                una experiencia de uso lujosa y confortable, sino que también
                posee propiedades térmicas y de transpirabilidad superiores, lo
                que la hace ideal para prendas de vestir que se adaptan a una
                variedad de climas y estaciones. Además, su durabilidad y
                resistencia al desgaste garantizan que las prendas
                confeccionadas con esta fibra mantengan su calidad y belleza
                durante mucho tiempo, convirtiéndola en una inversión atemporal.
              </p>
              <p
                className="text-xs mt-6 font-bold"
                style={{ letterSpacing: 2 }}
              >
                Micrones: 22 - 23
              </p>
            </div>
          )}
          <div
            className="mt-4 flex items-center justify-between"
            onClick={toggleLanaMerino}
          >
            <h2 className="uppercase text-2xl" style={{ letterSpacing: 2 }}>
              Lana Merino
            </h2>
            <button className="text-2xl">{showLanaMerino ? "-" : "+"}</button>
          </div>
          <div className="border-t border-primaryColor" />
          {showLanaMerino && (
            <div className="flex grid justify-center py-3 px-4">
              <p className="text-xs text-justify" style={{ letterSpacing: 2 }}>
                La lana merina es una fibra natural y renovable obtenida de las
                ovejas merinas. Su capacidad para regular la temperatura es una
                cualidad destacada, manteniendo a estas ovejas cómodas durante
                todas las estaciones del año. Esta misma propiedad se transfiere
                a las prendas de lana merina, proporcionando un confort térmico,
                suavidad, calidez, transpirabilidad, durabilidad y resistencia a
                las arrugas, lo que lo convierte en una de las mejores opciones.
              </p>
            </div>
          )}
          <div
            className="mt-4 flex items-center justify-between"
            onClick={toggleAlgodonOrganico}
          >
            <h2 className="uppercase text-2xl" style={{ letterSpacing: 2 }}>
              Algodón Orgánico
            </h2>
            <button className="text-2xl">
              {showAlgodonOrganico ? "-" : "+"}
            </button>
          </div>
          <div className="border-t border-primaryColor" />
          {showAlgodonOrganico && (
            <div className="flex grid justify-center py-3 px-4">
              <p className="text-xs text-justify" style={{ letterSpacing: 2 }}>
                El algodón orgánico se distingue por su cultivo bajo prácticas
                agrícolas sostenibles, que respetan el medio ambiente y la
                biodiversidad. Desde el proceso inicial de recolección, conocido
                como pizcado, donde se separan y limpian los copos de impurezas,
                hasta las etapas subsiguientes de cardado, peinado y estirado,
                cada fase es crucial para garantizar la pureza y calidad de la
                fibra. Una vez preparada, esta fibra versátil se convierte en la
                materia prima para una amplia variedad de tejidos, que abarcan
                desde prendas de vestir hasta textiles para el hogar. La
                diversidad en tamaño, peso y aplicación de estos tejidos refleja
                la versatilidad y adaptabilidad del algodón orgánico, que
                encuentra su lugar en la moda, el diseño y la industria textil
                en general.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FiberCatalog;
