// homeService.ts

import { apiRequest } from "./newBaseService";
import { Product, ProductGeneral } from "./productsService";

export interface Collection {
  id: number;
  name: string;
  description: string;
  nameButton: string;
  imagePath: string;
}

export interface CollectionProductHome {
  id: number;
  collection: Collection;
  products: ProductGeneral[];
}

interface GeneralResponse {
  message: string;
  data: CollectionProductHome[];
}

export const getCollectionProductHome = async (): Promise<GeneralResponse> => {
  try {
    const response = await apiRequest<GeneralResponse>(
      "shopping/homeCollection",
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(`Error fetching collection products: ${error.message}`);
  }
};

// import { myFetch, ErrorHandler, HttpMethod } from "./baseService";
// import { Product } from "./productsService";

// interface Collection {
//   id: number;
//   name: string;
//   description: string;
//   nameButton: string;
//   imagePath: string;
// }

// export interface CollectionProductHome {
//   id: number;
//   collection: Collection;
//   products: Product[];
// }

// interface GeneralResponse {
//   message: string;
//   data: CollectionProductHome[];
// }

// type GeneralResponseHandler = (data: GeneralResponse) => void;

// export const configuration = async (
//   resultHandler: GeneralResponseHandler,
//   errorHandler: ErrorHandler
// ) =>
//   myFetch<GeneralResponse>(
//     "shoppings/configuration",
//     "",
//     resultHandler,
//     errorHandler,
//     HttpMethod.GET
//   );

// export const getCollectionProductHome = async (
//   resultHandler: GeneralResponseHandler,
//   errorHandler: ErrorHandler
// ) =>
//   myFetch<GeneralResponse>(
//     "shopping/homeCollection",
//     "",
//     resultHandler,
//     errorHandler,
//     HttpMethod.GET
//   );
