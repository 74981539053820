import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <hr />
      <div className="max-w-4xl mx-auto my-10 p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Políticas de Privacidad
        </h1>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            1. Recolección de Información
          </h2>
          <p className="text-gray-600 text-justify">
            En Ilaini, recopilamos información personal que usted nos
            proporciona al registrarse, realizar compras y al interactuar con
            nuestros servicios. Esto incluye, pero no se limita a, nombre,
            dirección, correo electrónico, número de teléfono y detalles de
            pago.
          </p>
          <p className="text-gray-600 mt-2 text-justify">
            Además, recopilamos información automáticamente a través de cookies
            y tecnologías similares para mejorar su experiencia de navegación y
            personalizar el contenido mostrado en nuestro sitio web.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            2. Uso de la Información
          </h2>
          <p className="text-gray-600 text-justify">
            La información que recopilamos se utiliza para procesar sus pedidos,
            gestionar su cuenta, mejorar nuestros servicios y comunicarnos con
            usted sobre actualizaciones o promociones.
          </p>
          <p className="text-gray-600 mt-2 text-justify">
            También utilizamos esta información para análisis internos, con el
            fin de mejorar la experiencia del usuario y optimizar el rendimiento
            de nuestro sitio web.
          </p>
          <p className="text-gray-600 mt-2 text-justify">
            En ningún caso compartiremos o venderemos su información personal a
            terceros sin su consentimiento previo, salvo que sea requerido por
            ley o para completar una transacción (por ejemplo, con empresas de
            envío).
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            3. Seguridad e Información de Registros
          </h2>
          <p className="text-gray-600 text-justify">
            En Ilaini, nos comprometemos a proteger su información personal.
            Implementamos medidas de seguridad físicas, electrónicas y
            administrativas para proteger los datos contra acceso no autorizado,
            pérdida o alteración.
          </p>
          <p className="text-gray-600 mt-2 text-justify">
            Almacenamos los registros de su actividad en el sitio web por un
            tiempo limitado y de manera segura, solo para los fines descritos en
            estas políticas de privacidad y conforme a la legislación vigente en
            Perú.
          </p>
          <p className="text-gray-600 mt-2 text-justify">
            A pesar de nuestros esfuerzos, no podemos garantizar una seguridad
            absoluta de la información transmitida a través de internet, por lo
            que le recomendamos mantener sus credenciales de acceso en un lugar
            seguro.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            4. Quejas e Información de Contacto
          </h2>
          <p className="text-gray-600">
            Si tiene alguna queja, consulta o desea ejercer sus derechos sobre
            la protección de sus datos personales, puede contactarnos a través
            de nuestro correo electrónico:
            <a
              href="mailto:contacto@ilaini.com"
              className="text-indigo-500 hover:underline"
            >
              {" "}contacto@ilaini.com
            </a>
            .
          </p>
          <p className="text-gray-600 mt-2 text-justify">
            Nos comprometemos a atender sus solicitudes en un plazo máximo de 10
            días hábiles y a brindarle la asistencia necesaria para resolver
            cualquier inconveniente relacionado con la privacidad de sus datos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            5. Modificaciones a las Políticas de Privacidad
          </h2>
          <p className="text-gray-600 text-justify">
            Ilaini se reserva el derecho de actualizar estas políticas de
            privacidad en cualquier momento. Cualquier cambio será publicado en
            esta página, y el uso continuo de nuestros servicios después de la
            publicación de dichos cambios implica la aceptación de los mismos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            6. Aceptación de las Políticas
          </h2>
          <p className="text-gray-600 text-justify">
            Al utilizar nuestro sitio web y servicios, usted confirma que ha
            leído y entendido estas políticas de privacidad y acepta los
            términos aquí descritos.
          </p>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;
